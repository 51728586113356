/*
 * Config
 * @ Variables
 *
*/
/*calendar dropdown*/
/*
 *  Radius
*/
/*
 *  Use Defaults
*/
/*
 *  YWW base Font size
*/
/*
 * H1
*/
/*
 * H2
*/
/*
 * H3
*/
/*
 * H4
*/
/*
 * H5
*/
/*
 * H6
*/
/*
 * H7
*/
/*
 * H8
*/
/*
 * H9
*/
/*
 * H10
*/
/*
 * body-1
*/
/*
 * body-2
*/
/*
 * body-3
*/
/*
 * body-4
*/
/*
 * body-5
*/
/*
 * body-6
*/
/*
 * H1
*/
/*
 * H2
*/
/*
 * H3
*/
/*
 * H4
*/
/*
 * H5
*/
/*
 * H6
*/
/*
 * H7
*/
/*
 * body-1
*/
/*
 * body-2
*/
/*
 * body-3
*/
/*
 * body-4
*/
/*
 * body-5
*/
/*
 * body-6
*/
/* media carousel overlay */
/*
 * H1
*/
/*
 * H2
*/
/*
 * H3
*/
/*
 * H4
*/
/*
* form placeholder variables
*/
/*
 * Base
 * @ Colors
 *
*/
/* Colors */
/* Trip Advisor Color */
/* Guest checkout Color */
/*
 * Line height font
 */
/*
 * Border Radius
 */
/*
 * Border Radius Separate
 */
/*
 * Box Shadow
 */
/*
 * Widescreen
 */
/*
 * Desktop (PC's)
 */
/*
 * Wide screen Desktop (PC's)
 */
/*
 * Extra Wide screen Desktop (PC's)
 */
/*
 * Tablet
 */
/*
  * TabletOnly
  */
/*
 * upto Tablet
 */
/*
 * Tablet2
 */
/*
 * Mobile
 */
/*
 * No Screen
 */
/*
 * Transition
    usage example
      a{
      color: gray;
      @include transition(color .3s ease);
      &:hover {
        color: black;
      }
}
 */
/*
 * Opacity
 */
/*
 * Clearfix
 */
/*
 * Configure vendor
 */
/*
 * SVG stroke and fill
 */
/*
 * Transform
 */
/**
*   Use Arrow inside the :after and :before pseudo elements
*   and change the position as per needed
*   @include arrows('right', 7px, 7px, #000);
**/
/*
 * Flex
 */
/*
 * New Design System - 2025
 *
*/
/*
* Breakpoint
* @ Variables
*
*/
/*
    i/p -
        $available-maxwidth : maximum available width
        $column-width : width of 1 column
        $col-count : column no who's width has to be calculated
    o/p -
        column width of desired column no in %
    example -
        $available-max-width = 13200 , $column-width = 12
        avl width = 1320px = 100%
        width of 1px in % = 100 *(1 / 1320px)
        1 col-width in % = col-width * width of 1px in %
        86px(col-width) in % = 86 * (100 *(1 / 1320px)) = 6.51515%
*/
/*
    o/p -
        column width of desired column no in %
    i/p -
        $breakpoint : viewport at which width needs to be calculated
        $col-count : column no who's width has to be calculated
*/
/*
    o/p -
        returns value of gutter for 1 column in % as per the viewport
    i/p -
        $breakpoint : breakpoint at which width needs to be calculated
*/
/*
    o/p -
        returns
            $width: width of column w.r.t col-count
            $margin: margin-left/right for a column
    i/p -
        $breakpoint : breakpoint at which width needs to be calculated
        $col-count : value of column who's width has to be calculated
*/
/*
    o/p -
        returns
            $margin: margin-left/right for a column
    i/p -
        $breakpoint : breakpoint at which width needs to be calculated
*/
/*
    o/p -
        returns
            margin-left: left margin = width of 2 columns + gutter
    i/p -
        $breakpoint : breakpoint at which width needs to be calculated
        $col-count : value of column who's width has to be calculated
*/
/*
 * Base
 * @ Text
 *
*/
/*
 * Base
 * @ Gradients
 *
*/
/*
 * Line height font
 */
/*
 * Config
 * @ Variables
 *
*/
/*calendar dropdown*/
/*
 *  Radius
*/
/*
 *  Use Defaults
*/
/*
 *  YWW base Font size
*/
/*
 * H1
*/
/*
 * H2
*/
/*
 * H3
*/
/*
 * H4
*/
/*
 * H5
*/
/*
 * H6
*/
/*
 * H7
*/
/*
 * H8
*/
/*
 * H9
*/
/*
 * H10
*/
/*
 * body-1
*/
/*
 * body-2
*/
/*
 * body-3
*/
/*
 * body-4
*/
/*
 * body-5
*/
/*
 * body-6
*/
/*
 * H1
*/
/*
 * H2
*/
/*
 * H3
*/
/*
 * H4
*/
/*
 * H5
*/
/*
 * H6
*/
/*
 * H7
*/
/*
 * body-1
*/
/*
 * body-2
*/
/*
 * body-3
*/
/*
 * body-4
*/
/*
 * body-5
*/
/*
 * body-6
*/
/* media carousel overlay */
/*
 * H1
*/
/*
 * H2
*/
/*
 * H3
*/
/*
 * H4
*/
/*
* form placeholder variables
*/
/*
 * Base
 * @ Colors
 *
*/
/* Colors */
/* Trip Advisor Color */
/* Guest checkout Color */
/*
 * Line height font
 */
/*
 * Border Radius
 */
/*
 * Border Radius Separate
 */
/*
 * Box Shadow
 */
/*
 * Widescreen
 */
/*
 * Desktop (PC's)
 */
/*
 * Wide screen Desktop (PC's)
 */
/*
 * Extra Wide screen Desktop (PC's)
 */
/*
 * Tablet
 */
/*
  * TabletOnly
  */
/*
 * upto Tablet
 */
/*
 * Tablet2
 */
/*
 * Mobile
 */
/*
 * No Screen
 */
/*
 * Transition
    usage example
      a{
      color: gray;
      @include transition(color .3s ease);
      &:hover {
        color: black;
      }
}
 */
/*
 * Opacity
 */
/*
 * Clearfix
 */
/*
 * Configure vendor
 */
/*
 * SVG stroke and fill
 */
/*
 * Transform
 */
/**
*   Use Arrow inside the :after and :before pseudo elements
*   and change the position as per needed
*   @include arrows('right', 7px, 7px, #000);
**/
/*
 * Flex
 */
/*
 * New Design System - 2025
 *
*/
/*
* Breakpoint
* @ Variables
*
*/
/*
    i/p -
        $available-maxwidth : maximum available width
        $column-width : width of 1 column
        $col-count : column no who's width has to be calculated
    o/p -
        column width of desired column no in %
    example -
        $available-max-width = 13200 , $column-width = 12
        avl width = 1320px = 100%
        width of 1px in % = 100 *(1 / 1320px)
        1 col-width in % = col-width * width of 1px in %
        86px(col-width) in % = 86 * (100 *(1 / 1320px)) = 6.51515%
*/
/*
    o/p -
        column width of desired column no in %
    i/p -
        $breakpoint : viewport at which width needs to be calculated
        $col-count : column no who's width has to be calculated
*/
/*
    o/p -
        returns value of gutter for 1 column in % as per the viewport
    i/p -
        $breakpoint : breakpoint at which width needs to be calculated
*/
/*
    o/p -
        returns
            $width: width of column w.r.t col-count
            $margin: margin-left/right for a column
    i/p -
        $breakpoint : breakpoint at which width needs to be calculated
        $col-count : value of column who's width has to be calculated
*/
/*
    o/p -
        returns
            $margin: margin-left/right for a column
    i/p -
        $breakpoint : breakpoint at which width needs to be calculated
*/
/*
    o/p -
        returns
            margin-left: left margin = width of 2 columns + gutter
    i/p -
        $breakpoint : breakpoint at which width needs to be calculated
        $col-count : value of column who's width has to be calculated
*/
/*
 * Base
 * @ Text
 *
*/
/*
 * Base
 * @ Gradients
 *
*/
/*
 * Line height font
 */
.c-hero-component .download-offer .labelText {
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 800;
  letter-spacing: 1px;
  line-height: 1.29;
  font-size: 14px;
  font-size: 0.875rem;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
  text-align: left;
  color: #0073cd;
  cursor: pointer;
  text-decoration: underline;
  /* Medium devices (desktops, 1024px and up) */
}
@media (min-width: 768px) {
  .c-hero-component .download-offer .labelText {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
@media only screen and (min-width: 1024px) {
  .c-hero-component .download-offer .labelText {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
@media only screen and (min-width: 1366px) {
  .c-hero-component .download-offer .labelText {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
@media only screen and (min-width: 1024px) {
  .c-hero-component .download-offer .labelText:hover, .c-hero-component .download-offer .labelText:focus {
    color: #0073cd;
    text-decoration: none;
  }
}
.c-hero-component .download-offer .labelText.disabled, .c-hero-component .download-offer .labelText[disabled], fieldset[disabled] .c-hero-component .download-offer .labelText {
  text-align: left;
  color: #a4a4a4;
}
.c-hero-component .download-offer .download-offer-calendar--button {
  padding-right: 15px;
  position: relative;
  color: #000;
  text-decoration: none;
  text-align: center;
}
.lang-ar .c-hero-component .download-offer .download-offer-calendar--button {
  padding-left: 15px;
  padding-right: 0;
}
.c-hero-component .download-offer .download-offer-calendar--button::before, .c-hero-component .download-offer .download-offer-calendar--button::after {
  content: "";
  position: absolute;
  display: inline-block;
  width: 12px;
}
.c-hero-component .download-offer .download-offer-calendar--button::before {
  left: -12px;
  bottom: -1px;
  height: 1px;
  border-bottom: 1px solid #fff;
  z-index: 1;
}
.lang-ar .c-hero-component .download-offer .download-offer-calendar--button::before {
  right: -12px;
  left: auto;
}
.c-hero-component .download-offer .download-offer-calendar--button::after {
  right: 0;
  background: url("../../../assets/img/icon-download.svg") no-repeat center;
  bottom: 4px;
  height: 12px;
  z-index: 5;
}
.lang-ar .c-hero-component .download-offer .download-offer-calendar--button::after {
  left: 0;
  right: auto;
  right: auto;
}
.c-hero-component .download-offer .download-offer-calendar--button:hover {
  border-bottom: 1px solid #0073cd;
}
.c-hero-component .download-offer .download-offer-calendar--button:hover::before {
  left: 100%;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.lang-ar .c-hero-component .download-offer .download-offer-calendar--button:hover::before {
  right: 100%;
  left: auto;
}
.c-hero-component .download-offer .addeventatc_dropdown {
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.25);
  background-color: #f8f8f8;
  padding-bottom: 15px;
}
.c-hero-component .download-offer .addeventatc_dropdown span {
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 400;
  line-height: 1.67;
  letter-spacing: 0.6px;
  font-size: 12px;
  font-size: 0.75rem;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
  border-bottom: solid 2px #e0e0e0;
  background-color: #f8f8f8;
  font-weight: 600;
  margin: 0 20px;
}
@media (min-width: 768px) {
  .c-hero-component .download-offer .addeventatc_dropdown span {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
@media only screen and (min-width: 1024px) {
  .c-hero-component .download-offer .addeventatc_dropdown span {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
@media only screen and (min-width: 1366px) {
  .c-hero-component .download-offer .addeventatc_dropdown span {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
.c-hero-component .download-offer .addeventatc_dropdown span:last-of-type {
  border-bottom: solid 2px #e0e0e0;
}
.c-hero-component .download-offer .addeventatc_dropdown span:hover, .c-hero-component .download-offer .addeventatc_dropdown span:focus {
  border-bottom: 2px solid #0073cd;
}
.c-hero-component .download-offer .addeventatc_dropdown:after {
  border-bottom-color: #ffffff;
  border-width: 10px;
  margin-left: -10px;
}
.c-hero-component .download-offer .addeventatc_dropdown:before {
  border-bottom-color: #d3d0d0;
  border-width: 11px;
  margin-left: -11px;
}
.c-hero-component.component {
  margin-bottom: 50px;
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .c-hero-component.component {
    margin-bottom: 40px;
  }
}
.c-hero-component .hero-banner-container {
  margin-bottom: 80px;
  /* Small devices (tablets, 768px and up) */
  position: relative;
}
@media (min-width: 768px) {
  .c-hero-component .hero-banner-container {
    margin: 0 auto 100px;
    width: 100%;
  }
}
.c-hero-component .hero-banner-container .hero-banner-title {
  /* Medium devices (desktops, 1366px and up) */
}
@media only screen and (min-width: 1366px) {
  .c-hero-component .hero-banner-container .hero-banner-title {
    font-family: "Amaranth", "Open Sans", Arial, sans-serif;
    font-weight: 700;
    font-size: 30px;
    font-size: 1.875rem;
    letter-spacing: 0;
    line-height: 1.27;
    /* Small devices (tablets, 768px and up) */
    /* Medium devices (desktops, 1024px and up) */
    /* Medium devices (desktops, 1366px and up) */
  }
}
@media only screen and (min-width: 1366px) and (min-width: 768px) {
  .c-hero-component .hero-banner-container .hero-banner-title {
    font-size: 30px;
    font-size: 1.875rem;
  }
}
@media only screen and (min-width: 1366px) and (min-width: 1024px) {
  .c-hero-component .hero-banner-container .hero-banner-title {
    font-size: 40px;
    font-size: 2.5rem;
    line-height: 1.15;
  }
}
@media only screen and (min-width: 1366px) and (min-width: 1366px) {
  .c-hero-component .hero-banner-container .hero-banner-title {
    font-size: 50px;
    font-size: 3.125rem;
    line-height: 1.16;
  }
}
.c-hero-component .hero-image {
  height: auto;
  max-width: 100%;
  position: relative;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
}
.c-hero-component .hero-image:after {
  background: center bottom repeat-x;
  bottom: -20px;
  content: "";
  height: 100%;
  left: 0;
  max-height: 40px;
  position: absolute;
  width: 100%;
  /* < 768px */
}
@media (min-width: 320px) and (max-width: 767px) {
  .c-hero-component .hero-image:after {
    background-image: url(../../../assets/img/rope-footer-mobile.png);
  }
}
@media (min-width: 768px) {
  .c-hero-component .hero-image:after {
    background-image: url(../../../assets/img/rope-repeating.png);
    bottom: -10px;
    max-height: 50px;
  }
}
@media only screen and (min-width: 1024px) {
  .c-hero-component .hero-image {
    margin: 0 auto;
    overflow: hidden;
  }
  .c-hero-component .hero-image:after {
    bottom: 0;
  }
}
.c-hero-component .hero-image picture {
  display: block;
  margin: 0 auto;
  max-width: 1366px;
  position: relative;
  /* Medium devices (desktops, 1366px and up) */
}
@media only screen and (min-width: 1366px) {
  .c-hero-component .hero-image picture:before {
    background: url("../../../assets/img/watercolour-v-1-left.png") right bottom/contain no-repeat;
    content: "";
    height: 100%;
    max-height: 400px;
    max-width: 476px;
    position: absolute;
    right: 100%;
    width: 100%;
    z-index: -1;
  }
  .c-hero-component .hero-image picture:after {
    content: "";
    height: 100%;
    max-height: 400px;
    max-width: 476px;
    position: absolute;
    left: 100%;
    width: 100%;
    z-index: -1;
  }
}
.c-hero-component .hero-image picture img {
  height: auto;
}
.c-hero-component .hero-detail-container {
  background-color: #ffffff;
  box-shadow: -2px 2px 10px 0 rgba(215, 215, 215, 0.5), -2px 2px 10px 0 rgba(215, 215, 215, 0.5);
  color: #404040;
  height: auto;
  margin-top: -130px;
  padding: 30px;
  position: relative;
  text-align: center;
  z-index: 2;
  width: 95.2380952381%;
  max-width: 730.4761904762px;
  margin-left: 2.380952381%;
  margin-right: 2.380952381%;
  margin-left: auto;
  margin-right: auto;
  max-width: 330px;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  .c-hero-component .hero-detail-container {
    padding: 38px 40px;
    margin-top: -100px;
    top: auto;
    width: 63.9784946237%;
    max-width: 655.1397849462px;
    margin-left: 1.3440860215%;
    margin-right: 1.3440860215%;
    margin-left: auto;
    margin-right: auto;
  }
}
@media only screen and (min-width: 1024px) {
  .c-hero-component .hero-detail-container {
    bottom: 0;
    position: absolute;
    top: auto;
    width: 64.8484848485%;
    max-width: 885.8303030303px;
    margin-left: 0.9090909091%;
    margin-right: 0.9090909091%;
    margin: 0 auto;
    transform: translateY(50%);
  }
}
@media only screen and (min-width: 1366px) {
  .c-hero-component .hero-detail-container {
    width: 48.1818181818%;
    max-width: 658.1636363636px;
    margin-left: 0.9090909091%;
    margin-right: 0.9090909091%;
    margin-left: auto;
    margin-right: auto;
  }
}
.c-hero-component .hero-detail-container .hero-banner-shortTitle {
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 800;
  letter-spacing: 1px;
  line-height: 1.29;
  font-size: 14px;
  font-size: 0.875rem;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
  margin-bottom: 0;
  padding-bottom: 8px;
}
@media (min-width: 768px) {
  .c-hero-component .hero-detail-container .hero-banner-shortTitle {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
@media only screen and (min-width: 1024px) {
  .c-hero-component .hero-detail-container .hero-banner-shortTitle {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
@media only screen and (min-width: 1366px) {
  .c-hero-component .hero-detail-container .hero-banner-shortTitle {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
.c-hero-component .hero-detail-container .hero-banner-desc {
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 400;
  line-height: 1.71;
  letter-spacing: 0.7px;
  font-size: 14px;
  font-size: 0.875rem;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
  padding-top: 15px;
}
@media (min-width: 768px) {
  .c-hero-component .hero-detail-container .hero-banner-desc {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
@media only screen and (min-width: 1024px) {
  .c-hero-component .hero-detail-container .hero-banner-desc {
    font-size: 16px;
    font-size: 1rem;
    line-height: 1.63;
    letter-spacing: 0.8px;
  }
}
@media only screen and (min-width: 1366px) {
  .c-hero-component .hero-detail-container .hero-banner-desc {
    font-size: 16px;
    font-size: 1rem;
  }
}
.c-hero-component .hero-detail-container .hero-banner-desc h2 {
  font-family: "Amaranth", "Open Sans", Arial, sans-serif;
  font-weight: 700;
  font-size: 28px;
  font-size: 1.75rem;
  letter-spacing: 0;
  line-height: 1.29;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  .c-hero-component .hero-detail-container .hero-banner-desc h2 {
    font-size: 28px;
    font-size: 1.75rem;
  }
}
@media only screen and (min-width: 1024px) {
  .c-hero-component .hero-detail-container .hero-banner-desc h2 {
    font-size: 30px;
    font-size: 1.875rem;
    line-height: 1.2;
  }
}
@media only screen and (min-width: 1366px) {
  .c-hero-component .hero-detail-container .hero-banner-desc h2 {
    font-size: 40px;
    font-size: 2.5rem;
    line-height: 1.15;
  }
}
.c-hero-component .hero-detail-container .hero-banner-desc p {
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 400;
  line-height: 1.71;
  letter-spacing: 0.7px;
  font-size: 14px;
  font-size: 0.875rem;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  .c-hero-component .hero-detail-container .hero-banner-desc p {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
@media only screen and (min-width: 1024px) {
  .c-hero-component .hero-detail-container .hero-banner-desc p {
    font-size: 16px;
    font-size: 1rem;
    line-height: 1.63;
    letter-spacing: 0.8px;
  }
}
@media only screen and (min-width: 1366px) {
  .c-hero-component .hero-detail-container .hero-banner-desc p {
    font-size: 16px;
    font-size: 1rem;
  }
}
.c-hero-component.article-detail-hero {
  margin-bottom: 90px;
}
.c-hero-component.article-detail-hero .c-editorial-text .w--content {
  padding-bottom: 0;
  /* Medium devices (desktops, 1024px and up) */
}
.c-hero-component.article-detail-hero .c-editorial-text .w--content .body-copy-1 {
  text-align: left;
}
@media only screen and (min-width: 1024px) {
  .c-hero-component.article-detail-hero .c-editorial-text .w--content:after {
    top: -75px;
  }
}
.c-hero-component.article-detail-hero .hero-image picture {
  /* Small devices (tablets, 768px and up) */
}
@media (min-width: 768px) {
  .c-hero-component.article-detail-hero .hero-image picture:after {
    bottom: 50px;
  }
}
.c-hero-component.article-detail-hero .hero-banner-container {
  /* < 768px */
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
}
@media (min-width: 320px) and (max-width: 767px) {
  .c-hero-component.article-detail-hero .hero-banner-container {
    margin-bottom: 0;
  }
}
@media (min-width: 768px) {
  .c-hero-component.article-detail-hero .hero-banner-container {
    margin-top: -50px;
    margin-bottom: 0;
  }
}
@media only screen and (min-width: 1024px) {
  .c-hero-component.article-detail-hero .hero-banner-container {
    margin-top: 0;
  }
}
.c-hero-component.article-detail-hero .hero-detail-container {
  background-color: transparent;
  box-shadow: none;
  margin-top: 0;
  position: static;
  width: auto;
  transform: none;
  padding: 70px 0 0 0;
  /* Small devices (tablets, 768px and up) */
}
@media (min-width: 768px) {
  .c-hero-component.article-detail-hero .hero-detail-container {
    margin-top: 0;
    padding: 50px 0 0 0;
  }
}
.c-hero-component.article-detail-hero .hero-banner-title {
  width: 81.5151515152%;
  max-width: 1113.496969697px;
  margin-left: 0.9090909091%;
  margin-right: 0.9090909091%;
  margin: 0 auto 15px;
}
.c-hero-component.no-detail-container {
  margin-bottom: 45px;
  /* Small devices (tablets, 768px and up) */
}
@media (min-width: 768px) {
  .c-hero-component.no-detail-container {
    margin-bottom: 0;
  }
}
.c-hero-component.no-detail-container .hero-detail-container {
  display: none;
}
.c-hero-component.home {
  margin-bottom: 100px;
  /* Small devices (tablets, 768px and up) */
}
@media (min-width: 768px) {
  .c-hero-component.home {
    margin-bottom: 200px;
  }
}
.c-hero-component.home .hero-banner-container .hero-image {
  max-height: 400px;
}
.c-hero-component.home .hero-banner-container .hero-detail-container {
  background: #ffffff url(../../../assets/img/card-bg.png) left bottom/contain no-repeat;
  padding-bottom: 40px;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
}
@media (min-width: 768px) {
  .c-hero-component.home .hero-banner-container .hero-detail-container {
    margin: 0 auto;
    position: absolute;
    transform: translateY(-50%);
  }
}
@media only screen and (min-width: 1024px) {
  .c-hero-component.home .hero-banner-container .hero-detail-container {
    bottom: 0;
    transform: translateY(50%);
  }
}
.c-hero-component.home .hero-banner-container .hero-detail-container a {
  -webkit-border-top-left-radius: 18px;
  -webkit-border-top-right-radius: 0;
  -webkit-border-bottom-right-radius: 18px;
  -webkit-border-bottom-left-radius: 0;
  -moz-border-radius-topleft: 18px;
  -moz-border-radius-topright: 0;
  -moz-border-radius-bottomright: 18px;
  -moz-border-radius-bottomleft: 0;
  border-top-left-radius: 18px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 18px;
  border-bottom-left-radius: 0;
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 800;
  letter-spacing: 1px;
  line-height: 1.29;
  font-size: 14px;
  font-size: 0.875rem;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
  display: inline-block;
  margin-bottom: 0;
  padding: 10px 30px;
  position: relative;
  text-align: center;
  text-decoration: none;
  touch-action: manipulation;
  vertical-align: middle;
  white-space: nowrap;
  background-color: #ff9d1b;
  color: #404040;
  border: 2px solid #ff9d1b;
  /* Medium devices (desktops, 1024px and up) */
  margin-top: 13px;
  cursor: pointer;
}
@media (min-width: 768px) {
  .c-hero-component.home .hero-banner-container .hero-detail-container a {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
@media only screen and (min-width: 1024px) {
  .c-hero-component.home .hero-banner-container .hero-detail-container a {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
@media only screen and (min-width: 1366px) {
  .c-hero-component.home .hero-banner-container .hero-detail-container a {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
.c-hero-component.home .hero-banner-container .hero-detail-container a:focus {
  -webkit-border-top-left-radius: 18px;
  -webkit-border-top-right-radius: 0;
  -webkit-border-bottom-right-radius: 18px;
  -webkit-border-bottom-left-radius: 0;
  -moz-border-radius-topleft: 18px;
  -moz-border-radius-topright: 0;
  -moz-border-radius-bottomright: 18px;
  -moz-border-radius-bottomleft: 0;
  border-top-left-radius: 18px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 18px;
  border-bottom-left-radius: 0;
}
@media only screen and (min-width: 1024px) {
  .c-hero-component.home .hero-banner-container .hero-detail-container a:hover, .c-hero-component.home .hero-banner-container .hero-detail-container a:focus {
    background-color: #0073cd;
    color: #ffffff;
    opacity: 1;
    box-shadow: none;
    outline: 0;
    border: 2px solid #0073cd;
  }
}
.c-hero-component.home .hero-banner-container .hero-detail-container a.disabled, .c-hero-component.home .hero-banner-container .hero-detail-container a[disabled], fieldset[disabled] .c-hero-component.home .hero-banner-container .hero-detail-container a {
  background-color: #a4a4a4;
  color: #ffffff;
  border: 2px solid #a4a4a4;
}
.c-hero-component.home .editorial-grid-tooltip-wrapper {
  display: none;
}
.c-hero-component.home .editorial-text {
  display: none;
}
.c-hero-component.right {
  padding-bottom: 0;
}
.c-hero-component.right .hero-banner-container {
  width: 100%;
  margin-bottom: 50px;
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media only screen and (min-width: 1024px) {
  .c-hero-component.right .hero-banner-container {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-start;
    -moz-justify-content: flex-start;
    -ms-justify-content: flex-start;
    justify-content: flex-start;
    -ms-flex-pack: flex-start;
  }
  .c-hero-component.right .hero-banner-container:after {
    background: url(../../../assets/img/rope-repeating.png) repeat-x center center;
    bottom: -1px;
    content: "";
    display: block;
    height: 100%;
    max-height: 50px;
    position: absolute;
    width: 100%;
    left: 0;
  }
}
@media only screen and (min-width: 1441px) {
  .c-hero-component.right .hero-banner-container {
    justify-content: center;
  }
}
.c-hero-component.right .hero-banner-container:before {
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media only screen and (min-width: 1024px) {
  .c-hero-component.right .hero-banner-container:before {
    bottom: -3px;
  }
}
@media only screen and (min-width: 1366px) {
  .c-hero-component.right .hero-banner-container:before {
    bottom: 0;
  }
}
.c-hero-component.right .hero-banner-container .hero-image {
  height: auto;
  display: block;
  margin: 0;
  overflow: inherit;
  position: relative;
  max-height: 450px;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
.c-hero-component.right .hero-banner-container .hero-image picture {
  overflow: hidden;
}
.c-hero-component.right .hero-banner-container .hero-image:after {
  background: url(../../../assets/img/rope-repeating.png) repeat-x center center;
  bottom: -1px;
  content: "";
  display: block;
  height: 100%;
  max-height: 50px;
  position: absolute;
  width: 100%;
}
@media (min-width: 768px) {
  .c-hero-component.right .hero-banner-container .hero-image {
    overflow: hidden;
  }
}
@media only screen and (min-width: 1024px) {
  .c-hero-component.right .hero-banner-container .hero-image {
    overflow: inherit;
  }
  .c-hero-component.right .hero-banner-container .hero-image:after {
    content: none;
  }
  .c-hero-component.right .hero-banner-container .hero-image:before {
    left: 100%;
    transform: scale(-1);
    background: url("../../../assets/img/watercolour-v-1-left.png") right bottom/contain no-repeat;
    content: "";
    height: 100%;
    max-height: 450px;
    max-width: 476px;
    position: absolute;
    right: 100%;
    width: 100%;
    z-index: -1;
  }
}
@media only screen and (min-width: 1366px) {
  .c-hero-component.right .hero-banner-container .hero-image {
    width: 64.8484848485%;
    max-width: 885.8303030303px;
    margin-left: 0.9090909091%;
    margin-right: 0.9090909091%;
    margin: 0;
  }
  .c-hero-component.right .hero-banner-container .hero-image:after {
    background: url("../../../assets/img/watercolour-v-1-left.png") right bottom/contain no-repeat;
    content: "";
    top: 0;
    left: auto;
    right: 75%;
    max-height: 450px;
    z-index: -1;
  }
}
.c-hero-component.right .hero-banner-container .hero-detail-container {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center;
  -webkit-align-self: center;
  -moz-align-self: center;
  -ms-align-self: center;
  align-self: center;
  position: relative;
  box-shadow: -2px 2px 10px 0 rgba(215, 215, 215, 0.5);
  top: auto;
  bottom: auto;
  padding: 0;
  margin: 0 auto;
  margin-top: -70px;
  height: auto;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  .c-hero-component.right .hero-banner-container .hero-detail-container {
    margin-top: -90px;
  }
}
@media only screen and (min-width: 1024px) {
  .c-hero-component.right .hero-banner-container .hero-detail-container {
    margin: 0;
    overflow: hidden;
    transform: translateX(-50%);
  }
}
@media only screen and (min-width: 1366px) {
  .c-hero-component.right .hero-banner-container .hero-detail-container {
    width: 39.8484848485%;
    max-width: 544.3303030303px;
    margin-left: 0.9090909091%;
    margin-right: 0.9090909091%;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .c-hero-component.right .hero-banner-container .hero-detail-container {
    /* IE10+ CSS styles go here */
    width: 627px;
  }
}
.c-hero-component.right .hero-banner-container .hero-detail-container .contents {
  background-color: #ffffff;
  padding: 30px;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
}
@media (min-width: 768px) {
  .c-hero-component.right .hero-banner-container .hero-detail-container .contents {
    padding: 38px 40px 37px;
  }
}
@media only screen and (min-width: 1024px) {
  .c-hero-component.right .hero-banner-container .hero-detail-container .contents {
    margin: 0;
  }
}
.c-hero-component.left {
  overflow: hidden;
}
.c-hero-component.left .hero-banner-container {
  width: 100%;
  margin-bottom: 50px;
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
.c-hero-component.left .hero-banner-container:before {
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media only screen and (min-width: 1024px) {
  .c-hero-component.left .hero-banner-container:before {
    bottom: -3px;
  }
}
@media only screen and (min-width: 1366px) {
  .c-hero-component.left .hero-banner-container:before {
    bottom: 0;
  }
}
@media only screen and (min-width: 1024px) {
  .c-hero-component.left .hero-banner-container {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-start;
    -moz-justify-content: flex-start;
    -ms-justify-content: flex-start;
    justify-content: flex-start;
    -ms-flex-pack: flex-start;
    -webkit-flex-direction: row-reverse;
    -moz-flex-direction: row-reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
  }
  .c-hero-component.left .hero-banner-container:before {
    content: none;
  }
  .c-hero-component.left .hero-banner-container:after {
    background: url(../../../assets/img/rope-repeating.png) repeat-x center center;
    bottom: -1px;
    content: "";
    display: block;
    height: 100%;
    max-height: 50px;
    position: absolute;
    width: 100%;
    left: 0;
  }
}
@media only screen and (min-width: 1441px) {
  .c-hero-component.left .hero-banner-container {
    justify-content: center;
  }
}
.c-hero-component.left .hero-banner-container .hero-image {
  height: auto;
  display: block;
  margin: 0;
  overflow: inherit;
  position: relative;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
  max-height: 450px;
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  .c-hero-component.left .hero-banner-container .hero-image {
    overflow: hidden;
  }
}
@media only screen and (min-width: 1024px) {
  .c-hero-component.left .hero-banner-container .hero-image {
    overflow: inherit;
  }
}
@media only screen and (min-width: 1366px) {
  .c-hero-component.left .hero-banner-container .hero-image {
    width: 64.8484848485%;
    max-width: 885.8303030303px;
    margin-left: 0.9090909091%;
    margin-right: 0.9090909091%;
    margin: 0;
  }
}
.c-hero-component.left .hero-banner-container .hero-image picture {
  overflow: hidden;
}
@media (max-width: 1023px) {
  .c-hero-component.left .hero-banner-container .hero-image:after {
    background: url(../../../assets/img/rope-repeating.png) repeat-x center center;
    bottom: -1px;
    content: "";
    display: block;
    height: 100%;
    max-height: 50px;
    position: absolute;
    width: 100%;
  }
}
@media only screen and (min-width: 1024px) {
  .c-hero-component.left .hero-banner-container .hero-image:after {
    content: none;
  }
}
.c-hero-component.left .hero-banner-container .hero-image:before {
  background: url("../../../assets/img/watercolour-v-1-left.png") right bottom/contain no-repeat;
  content: "";
  height: 100%;
  max-height: 450px;
  max-width: 476px;
  position: absolute;
  right: 100%;
  width: 100%;
  z-index: -1;
}
@media only screen and (min-width: 1366px) {
  .c-hero-component.left .hero-banner-container .hero-image:after {
    background: url("../../../assets/img/watercolour-v-1-right.png") right bottom/contain no-repeat;
    content: "";
    left: 40%;
    right: auto;
    top: 0;
    height: 100%;
    max-height: 450px;
    z-index: -1;
  }
}
.c-hero-component.left .hero-banner-container .hero-detail-container {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center;
  -webkit-align-self: center;
  -moz-align-self: center;
  -ms-align-self: center;
  align-self: center;
  position: relative;
  box-shadow: -2px 2px 10px 0 rgba(215, 215, 215, 0.5);
  top: auto;
  bottom: auto;
  padding: 0;
  margin: 0 auto;
  margin-top: -70px;
  height: auto;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  .c-hero-component.left .hero-banner-container .hero-detail-container {
    margin-top: -90px;
  }
}
@media only screen and (min-width: 1024px) {
  .c-hero-component.left .hero-banner-container .hero-detail-container {
    overflow: hidden;
    transform: translateX(50%);
    margin: 0 0 0 -100px;
  }
}
@media only screen and (min-width: 1366px) {
  .c-hero-component.left .hero-banner-container .hero-detail-container {
    width: 39.8484848485%;
    max-width: 544.3303030303px;
    margin-left: 0.9090909091%;
    margin-right: 0.9090909091%;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .c-hero-component.left .hero-banner-container .hero-detail-container {
    /* IE10+ CSS styles go here */
    width: 627px;
  }
}
.c-hero-component.left .hero-banner-container .hero-detail-container .contents {
  background-color: #ffffff;
  padding: 30px;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
}
@media (min-width: 768px) {
  .c-hero-component.left .hero-banner-container .hero-detail-container .contents {
    padding: 38px 40px 37px;
  }
}
@media only screen and (min-width: 1024px) {
  .c-hero-component.left .hero-banner-container .hero-detail-container .contents {
    margin: 0;
  }
}
.c-hero-component.center .hero-banner-container {
  margin-bottom: 80px;
  /* Small devices (tablets, 768px and up) */
}
@media (min-width: 768px) {
  .c-hero-component.center .hero-banner-container {
    margin-bottom: 100px;
  }
}
.c-hero-component.center .hero-banner-container .hero-detail-container {
  /* Small devices (tablets, 768px and up) */
}
@media (min-width: 768px) {
  .c-hero-component.center .hero-banner-container .hero-detail-container {
    bottom: 0;
    margin: 0 auto;
    position: absolute;
    transform: translateY(50%);
  }
}
.c-hero-component.regular .hero-banner-container .hero-detail-container {
  background: #ffffff url(../../../assets/img/card-bg.png) left bottom/contain no-repeat;
  top: auto;
  margin: 0 auto;
  bottom: 0;
  position: absolute;
  transform: translateY(50%);
}
.c-hero-component.left-splash .editorial-text .w--content:first-child {
  position: relative;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
}
.c-hero-component.left-splash .editorial-text .w--content:first-child:after {
  z-index: -1;
  content: "";
  position: absolute;
  left: -9px;
  top: 0;
  width: 282px;
  height: 253px;
  background: #ffffff url("../../../assets/img/watercolour-6-bg-editorial-txt-right-mobile.png") no-repeat right top;
  transform: scaleX(-1);
}
@media (min-width: 768px) {
  .c-hero-component.left-splash .editorial-text .w--content:first-child:after {
    left: -10px;
    background: #ffffff url("../../../assets/img/watercolour-6-bg-editorial-txt-right-tablet.png") no-repeat right top;
  }
}
@media only screen and (min-width: 1024px) {
  .c-hero-component.left-splash .editorial-text .w--content:first-child:after {
    background: #ffffff url("../../../assets/img/watercolour-6-bg-editorial-txt-right-desktop.png") no-repeat right top;
    top: 0;
    left: -170px;
  }
}
.c-hero-component.right-splash .editorial-text .w--content:first-child {
  position: relative;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
}
.c-hero-component.right-splash .editorial-text .w--content:first-child:after {
  z-index: -1;
  content: "";
  position: absolute;
  right: -9px;
  top: 0;
  width: 282px;
  height: 253px;
  background: #ffffff url("../../../assets/img/watercolour-6-bg-editorial-txt-right-mobile.png") no-repeat right top;
}
@media (min-width: 768px) {
  .c-hero-component.right-splash .editorial-text .w--content:first-child:after {
    right: -10px;
    background: #ffffff url("../../../assets/img/watercolour-6-bg-editorial-txt-right-tablet.png") no-repeat right top;
  }
}
@media only screen and (min-width: 1024px) {
  .c-hero-component.right-splash .editorial-text .w--content:first-child:after {
    background: #ffffff url("../../../assets/img/watercolour-6-bg-editorial-txt-right-desktop.png") no-repeat right top;
    top: 0;
    right: -170px;
  }
}
.c-hero-component.both-splash .editorial-text {
  position: relative;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
}
.c-hero-component.both-splash .editorial-text:before {
  z-index: -1;
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 214px;
  height: 265px;
  background: #ffffff url("../../../assets/img/watercolour-6-bg-editorial-txt-right-mobile.png") no-repeat right top;
  transform: scaleX(-1);
}
.c-hero-component.both-splash .editorial-text:after {
  z-index: -1;
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  width: 282px;
  height: 253px;
  background: #ffffff url("../../../assets/img/watercolour-6-bg-editorial-txt-right-mobile.png") no-repeat right top;
}
@media (min-width: 768px) {
  .c-hero-component.both-splash .editorial-text:before {
    left: -10px;
    background: #ffffff url("../../../assets/img/watercolour-6-bg-editorial-txt-right-tablet.png") no-repeat right top;
  }
  .c-hero-component.both-splash .editorial-text:after {
    right: -10px;
    background: #ffffff url("../../../assets/img/watercolour-6-bg-editorial-txt-right-tablet.png") no-repeat right top;
  }
}
@media only screen and (min-width: 1024px) {
  .c-hero-component.both-splash .editorial-text:before {
    background: #ffffff url("../../../assets/img/watercolour-7-bg-editorial-txt-left-desktop.png") no-repeat right top;
    left: 0;
    transform: scaleX(1);
  }
  .c-hero-component.both-splash .editorial-text:after {
    background: #ffffff url("../../../assets/img/watercolour-7-bg-editorial-txt-right-desktop.png") no-repeat right top;
    right: 0;
  }
}
.c-hero-component .editorial-grid-tooltip-wrapper {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-top: 13px;
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media only screen and (min-width: 1024px) {
  .c-hero-component .editorial-grid-tooltip-wrapper {
    display: block;
    width: 89.8484848485%;
    max-width: 1227.3303030303px;
    margin-left: 0.9090909091%;
    margin-right: 0.9090909091%;
    margin-right: auto;
    margin-left: auto;
    padding-top: 28px;
  }
}
@media only screen and (min-width: 1366px) {
  .c-hero-component .editorial-grid-tooltip-wrapper {
    width: 81.5151515152%;
    max-width: 1113.496969697px;
    margin-left: 0.9090909091%;
    margin-right: 0.9090909091%;
    max-width: 1366px;
    margin-right: auto;
    margin-left: auto;
  }
}
.c-hero-component .editorial-grid-tooltip-wrapper img {
  width: 60px;
}
.c-hero-component .editorial-grid-tooltip-wrapper .more-content-visible {
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 800;
  letter-spacing: 1px;
  line-height: 1.38;
  font-size: 13px;
  font-size: 0.8125rem;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
  margin: 0 auto 18px;
  color: #0073cd;
  border-bottom: 2px solid #0073cd;
  /* Small devices (tablets, 768px and up) */
}
@media (min-width: 768px) {
  .c-hero-component .editorial-grid-tooltip-wrapper .more-content-visible {
    font-size: 13px;
    font-size: 0.8125rem;
  }
}
@media only screen and (min-width: 1024px) {
  .c-hero-component .editorial-grid-tooltip-wrapper .more-content-visible {
    font-size: 13px;
    font-size: 0.8125rem;
  }
}
@media only screen and (min-width: 1366px) {
  .c-hero-component .editorial-grid-tooltip-wrapper .more-content-visible {
    font-size: 13px;
    font-size: 0.8125rem;
  }
}
@media (min-width: 768px) {
  .c-hero-component .editorial-grid-tooltip-wrapper .more-content-visible {
    display: none;
  }
}
.c-hero-component .editorial-grid-tooltip-wrapper .more-content-hidden {
  display: none;
}
.c-hero-component .editorial-grid-icon {
  width: 95.2380952381%;
  max-width: 730.4761904762px;
  margin-left: 2.380952381%;
  margin-right: 2.380952381%;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
}
@media (min-width: 768px) {
  .c-hero-component .editorial-grid-icon {
    width: 97.311827957%;
    max-width: 996.4731182796px;
    margin-left: 1.3440860215%;
    margin-right: 1.3440860215%;
  }
}
@media only screen and (min-width: 1024px) {
  .c-hero-component .editorial-grid-icon {
    width: 98.1818181818%;
    max-width: 1341.1636363636px;
    margin-left: 0.9090909091%;
    margin-right: 0.9090909091%;
    -webkit-flex-wrap: nowrap;
    -moz-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
}
.c-hero-component .c-editorial-grid-tooltip {
  margin-bottom: 32px;
  width: 45.2380952381%;
  max-width: 346.9761904762px;
  padding-left: 2.380952381%;
  padding-right: 2.380952381%;
  text-align: center;
  /* < 768px */
  /* Medium devices (desktops, 1024px and up) */
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
}
.c-hero-component .c-editorial-grid-tooltip .product-tooltip {
  margin: 0;
}
.c-hero-component .c-editorial-grid-tooltip .product-tooltip-body {
  text-align: initial;
  top: 85px;
  left: 53%;
  padding: 15px;
  width: 180px;
  transform: translateX(-48%);
  z-index: 1;
  -webkit-border-radius: 4px;
  -khtml-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
  /* < 768px */
  /* Medium devices (desktops, 1024px and up) */
}
.c-hero-component .c-editorial-grid-tooltip .product-tooltip-body ul {
  margin-left: 18px;
  text-align: left;
}
.c-hero-component .c-editorial-grid-tooltip .product-tooltip-body:before {
  left: 45%;
}
@media (min-width: 320px) and (max-width: 767px) {
  .c-hero-component .c-editorial-grid-tooltip .product-tooltip-body:after {
    content: "";
    display: block;
    height: 15px;
    width: 15px;
  }
}
@media only screen and (min-width: 1024px) {
  .c-hero-component .c-editorial-grid-tooltip .product-tooltip-body {
    transform: translateX(-51%);
  }
}
.c-hero-component .c-editorial-grid-tooltip .product-tooltip-body img {
  display: none;
}
@media (min-width: 320px) and (max-width: 767px) {
  .c-hero-component .c-editorial-grid-tooltip:nth-child(odd):not(:last-child) .product-tooltip-body {
    padding: 20px 15px;
    transform: translateX(-24%);
  }
  .c-hero-component .c-editorial-grid-tooltip:nth-child(odd):not(:last-child) .product-tooltip-body::before {
    left: 19.9%;
  }
  .c-hero-component .c-editorial-grid-tooltip:nth-child(even) .product-tooltip-body {
    transform: translateX(-78%);
  }
  .c-hero-component .c-editorial-grid-tooltip:nth-child(even) .product-tooltip-body::before {
    left: 74.4%;
  }
  .c-hero-component .c-editorial-grid-tooltip:last-child() .product-tooltip-body {
    padding: 20px 15px;
    transform: translateX(-72%);
  }
  .c-hero-component .c-editorial-grid-tooltip:last-child() .product-tooltip-body:before {
    left: 68.3%;
  }
}
@media only screen and (min-width: 1024px) {
  .c-hero-component .c-editorial-grid-tooltip {
    width: 18.1818181818%;
    max-width: 248.3636363636px;
    padding-left: 0.9090909091%;
    padding-right: 0.9090909091%;
    border-right: 1px dotted #d3d0d0;
  }
  .c-hero-component .c-editorial-grid-tooltip:last-of-type {
    border: none;
  }
  .c-hero-component .c-editorial-grid-tooltip .product-tooltip-body {
    transform: translateX(-50%);
  }
  .c-hero-component .c-editorial-grid-tooltip .product-tooltip-body:before {
    left: 48%;
  }
}
@media (min-width: 768px) {
  .c-hero-component .c-editorial-grid-tooltip {
    width: 22.311827957%;
    max-width: 228.4731182796px;
    padding-left: 1.3440860215%;
    padding-right: 1.3440860215%;
  }
}
@media only screen and (min-width: 1024px) {
  .c-hero-component .c-editorial-grid-tooltip {
    width: 18.1818181818%;
    max-width: 248.3636363636px;
    padding-left: 0.9090909091%;
    padding-right: 0.9090909091%;
  }
}
.c-hero-component .short-title {
  display: block;
  padding-top: 24px;
}
.c-hero-component .short-title .body-copy-4 {
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 800;
  letter-spacing: 0.8px;
  line-height: 1.33;
  font-size: 12px;
  font-size: 0.75rem;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
  text-align: center;
}
@media (min-width: 768px) {
  .c-hero-component .short-title .body-copy-4 {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
@media only screen and (min-width: 1024px) {
  .c-hero-component .short-title .body-copy-4 {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
@media only screen and (min-width: 1366px) {
  .c-hero-component .short-title .body-copy-4 {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
.c-hero-component .short-title .desc {
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 400;
  line-height: 1.67;
  letter-spacing: 0.6px;
  font-size: 12px;
  font-size: 0.75rem;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
  margin-top: 10px;
}
@media (min-width: 768px) {
  .c-hero-component .short-title .desc {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
@media only screen and (min-width: 1024px) {
  .c-hero-component .short-title .desc {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
@media only screen and (min-width: 1366px) {
  .c-hero-component .short-title .desc {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
.lang-ar .c-hero-component .short-title .desc {
  font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.6px;
  font-size: 12px;
  font-size: 0.75rem;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  .lang-ar .c-hero-component .short-title .desc {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
@media only screen and (min-width: 1024px) {
  .lang-ar .c-hero-component .short-title .desc {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
@media only screen and (min-width: 1366px) {
  .lang-ar .c-hero-component .short-title .desc {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
.c-hero-component .product-tooltip {
  margin: 0;
}
.c-hero-component .product-tooltip:after {
  content: "";
  position: absolute;
  width: 0;
  bottom: -9px;
  left: 50%;
  border-top: solid 3px #ffffff;
  border-right: solid 2px transparent;
  border-left: solid 2px transparent;
  transform: translate(-50%);
}
.c-hero-component .product-tooltip .close-tooltip {
  position: absolute;
  top: 7px;
  right: 9px;
  cursor: pointer;
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .c-hero-component .product-tooltip .close-tooltip {
    display: none;
  }
}
.c-hero-component .product-tooltip .close-tooltip svg.icn-close {
  width: 10px;
  height: 10px;
  stroke: #333333;
}
.c-hero-component .product-tooltip .body-copy-4 {
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 800;
  letter-spacing: 1.2px;
  line-height: 1.22;
  font-size: 18px;
  font-size: 1.125rem;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
  text-align: initial;
  font-weight: 700;
  /* Medium devices (desktops, 1024px and up) */
}
@media (min-width: 768px) {
  .c-hero-component .product-tooltip .body-copy-4 {
    font-size: 18px;
    font-size: 1.125rem;
  }
}
@media only screen and (min-width: 1024px) {
  .c-hero-component .product-tooltip .body-copy-4 {
    font-size: 18px;
    font-size: 1.125rem;
  }
}
@media only screen and (min-width: 1366px) {
  .c-hero-component .product-tooltip .body-copy-4 {
    font-size: 18px;
    font-size: 1.125rem;
  }
}
.lang-ar .c-hero-component .product-tooltip .body-copy-4 {
  font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
  font-weight: 700;
  letter-spacing: 1.2px;
  line-height: 1.22;
  font-size: 18px;
  font-size: 1.125rem;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  .lang-ar .c-hero-component .product-tooltip .body-copy-4 {
    font-size: 18px;
    font-size: 1.125rem;
  }
}
@media only screen and (min-width: 1024px) {
  .lang-ar .c-hero-component .product-tooltip .body-copy-4 {
    font-size: 18px;
    font-size: 1.125rem;
  }
}
@media only screen and (min-width: 1366px) {
  .lang-ar .c-hero-component .product-tooltip .body-copy-4 {
    font-size: 18px;
    font-size: 1.125rem;
  }
}
@media only screen and (min-width: 1024px) {
  .c-hero-component .product-tooltip .body-copy-4 {
    top: 16%;
  }
}
.c-hero-component .product-tooltip .header-bottom-profile-name {
  margin-top: 8px;
  padding: 0;
  text-align: initial;
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 400;
  line-height: 1.67;
  letter-spacing: 0.6px;
  font-size: 12px;
  font-size: 0.75rem;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  .c-hero-component .product-tooltip .header-bottom-profile-name {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
@media only screen and (min-width: 1024px) {
  .c-hero-component .product-tooltip .header-bottom-profile-name {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
@media only screen and (min-width: 1366px) {
  .c-hero-component .product-tooltip .header-bottom-profile-name {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
.lang-ar .c-hero-component .product-tooltip .header-bottom-profile-name {
  font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.6px;
  font-size: 12px;
  font-size: 0.75rem;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  .lang-ar .c-hero-component .product-tooltip .header-bottom-profile-name {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
@media only screen and (min-width: 1024px) {
  .lang-ar .c-hero-component .product-tooltip .header-bottom-profile-name {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
@media only screen and (min-width: 1366px) {
  .lang-ar .c-hero-component .product-tooltip .header-bottom-profile-name {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
.c-hero-component .product-tooltip .editorial-grid-tile-icon {
  width: 60px;
}
.c-hero-component.v-gradient-filter .hero-image picture:after {
  content: "";
  background-image: linear-gradient(to top, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0) 40%, rgba(255, 255, 255, 0) 100%);
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
}
@media (min-width: 768px) {
  .c-hero-component.v-gradient-filter .hero-image picture:after {
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 30%, rgba(255, 255, 255, 0) 100%);
  }
}
@media only screen and (min-width: 1024px) {
  .c-hero-component.v-gradient-filter .hero-image picture:after {
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 30%, rgba(255, 255, 255, 0) 100%);
  }
}
.c-hero-component.v-gradient-filter.hero-carousel .c-media-carousel .c-media-carousel-view-container {
  max-width: initial;
  height: auto;
}
.c-hero-component.v-gradient-filter.hero-carousel .c-media-carousel .c-media-carousel-view-container:after {
  content: "";
  display: block;
  background-image: linear-gradient(to top, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0) 40%, rgba(255, 255, 255, 0) 100%);
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: cover;
  top: 0;
  z-index: 1;
  left: 0;
  max-width: none;
  max-height: none;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
}
@media (min-width: 768px) {
  .c-hero-component.v-gradient-filter.hero-carousel .c-media-carousel .c-media-carousel-view-container:after {
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 30%, rgba(255, 255, 255, 0) 100%);
  }
}
@media only screen and (min-width: 1024px) {
  .c-hero-component.v-gradient-filter.hero-carousel .c-media-carousel .c-media-carousel-view-container:after {
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 30%, rgba(255, 255, 255, 0) 100%);
  }
}
.experience-editor-mode .c-hero-component.v-gradient-filter.hero-carousel .c-media-carousel .c-media-carousel-view-container:after {
  pointer-events: none;
}
.c-hero-component.farah-fullwidth.component {
  margin-bottom: 50px;
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .c-hero-component.farah-fullwidth.component {
    margin-bottom: 40px;
  }
}
.c-hero-component.farah-fullwidth .hero-image:after {
  content: none;
}
.c-hero-component.farah-fullwidth .hero-image picture {
  max-width: initial;
  height: auto;
}
.c-hero-component.farah-fullwidth .hero-image picture:after {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: cover;
  top: 0;
  z-index: 1;
  left: 0;
  max-width: none;
  max-height: none;
}
.c-hero-component.farah-fullwidth.left .hero-banner-container, .c-hero-component.farah-fullwidth.right .hero-banner-container, .c-hero-component.farah-fullwidth.center .hero-banner-container {
  margin-bottom: 0;
}
.c-hero-component.farah-fullwidth.left .hero-banner-container .hero-image, .c-hero-component.farah-fullwidth.right .hero-banner-container .hero-image, .c-hero-component.farah-fullwidth.center .hero-banner-container .hero-image {
  width: 100%;
  max-width: initial;
  max-height: 100%;
  overflow: initial;
}
.c-hero-component.farah-fullwidth.left .hero-banner-container .hero-image:after, .c-hero-component.farah-fullwidth.right .hero-banner-container .hero-image:after, .c-hero-component.farah-fullwidth.center .hero-banner-container .hero-image:after {
  content: none;
}
.c-hero-component.farah-fullwidth.left .hero-banner-container:after, .c-hero-component.farah-fullwidth.right .hero-banner-container:after, .c-hero-component.farah-fullwidth.center .hero-banner-container:after {
  content: none;
}
.c-hero-component.farah-fullwidth.left .hero-banner-container .hero-detail-container, .c-hero-component.farah-fullwidth.right .hero-banner-container .hero-detail-container, .c-hero-component.farah-fullwidth.center .hero-banner-container .hero-detail-container {
  box-shadow: none;
}
.c-hero-component.farah-fullwidth.left .hero-banner-container .hero-detail-container .contents, .c-hero-component.farah-fullwidth.right .hero-banner-container .hero-detail-container .contents, .c-hero-component.farah-fullwidth.center .hero-banner-container .hero-detail-container .contents {
  padding: 0;
  background-color: transparent;
}
.c-hero-component.farah-fullwidth.right .hero-banner-container .hero-image:before {
  content: none;
}
.c-hero-component.farah-fullwidth .hero-detail-container {
  bottom: 0;
  top: auto;
  margin: 25px auto;
  text-align: center;
  box-shadow: none;
  /* Small devices (tablets, 768px and up) */
}
@media (min-width: 768px) {
  .c-hero-component.farah-fullwidth .hero-detail-container {
    top: 50%;
    bottom: auto;
    margin: 0 auto;
    text-align: left;
  }
}
.c-hero-component.farah-fullwidth.left .hero-banner-container .hero-detail-container, .c-hero-component.farah-fullwidth.right .hero-banner-container .hero-detail-container, .c-hero-component.farah-fullwidth.center .hero-banner-container .hero-detail-container {
  background-color: transparent;
  position: absolute;
  margin: 25px auto;
  padding: 0;
  top: auto;
  bottom: 0;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
}
@media (min-width: 768px) {
  .c-hero-component.farah-fullwidth.left .hero-banner-container .hero-detail-container, .c-hero-component.farah-fullwidth.right .hero-banner-container .hero-detail-container, .c-hero-component.farah-fullwidth.center .hero-banner-container .hero-detail-container {
    bottom: auto;
    width: 38.9784946237%;
    max-width: 399.1397849462px;
    margin-left: 1.3440860215%;
    margin-right: 1.3440860215%;
    margin: 0;
    margin-left: 9.6774193548%;
    top: 50%;
    transform: translateY(-50%);
    padding-right: 40px;
    background: none;
  }
}
@media only screen and (min-width: 1024px) {
  .c-hero-component.farah-fullwidth.left .hero-banner-container .hero-detail-container, .c-hero-component.farah-fullwidth.right .hero-banner-container .hero-detail-container, .c-hero-component.farah-fullwidth.center .hero-banner-container .hero-detail-container {
    width: 39.8484848485%;
    max-width: 544.3303030303px;
    margin-left: 0.9090909091%;
    margin-right: 0.9090909091%;
    margin: 0;
    margin-left: 9.2424242424%;
    padding-right: 62px;
  }
}
.c-hero-component.farah-fullwidth.center .hero-detail-container {
  position: absolute;
  margin: 0 auto;
}
.c-hero-component.farah-fullwidth.center .hero-detail-container.w--content {
  /* Small devices (tablets, 768px and up) */
}
@media (min-width: 768px) {
  .c-hero-component.farah-fullwidth.center .hero-detail-container.w--content {
    margin: 0 auto;
    padding: 0;
  }
}
.c-hero-component.farah-fullwidth.center .hero-banner-container .contents {
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .c-hero-component.farah-fullwidth.center .hero-banner-container .contents:before {
    left: calc(50% - 32px);
  }
}
.c-hero-component.farah-fullwidth.center .hero-banner-title,
.c-hero-component.farah-fullwidth.center .hero-banner-desc,
.c-hero-component.farah-fullwidth.center .hero-banner-shortTitle {
  text-align: center;
}
.c-hero-component.farah-fullwidth.right {
  /* Small devices (tablets, 768px and up) */
}
@media (min-width: 768px) {
  .c-hero-component.farah-fullwidth.right .hero-detail-container {
    right: 0;
    left: auto;
    /* Small devices (tablets, 768px and up) */
  }
  .c-hero-component.farah-fullwidth.right .hero-detail-container.w--content {
    /* Small devices (tablets, 768px and up) */
  }
}
@media (min-width: 768px) and (min-width: 768px) {
  .c-hero-component.farah-fullwidth.right .hero-detail-container.w--content {
    padding: 0;
    margin-right: 8.33333%;
  }
}
@media (min-width: 768px) and (min-width: 768px) {
  .c-hero-component.farah-fullwidth.right .hero-detail-container {
    text-align: right;
  }
}
@media (min-width: 768px) {
  .c-hero-component.farah-fullwidth.right .hero-banner-title,
  .c-hero-component.farah-fullwidth.right .hero-banner-desc,
  .c-hero-component.farah-fullwidth.right .hero-banner-shortTitle {
    text-align: right;
  }
}
.c-hero-component.farah-fullwidth.right .hero-banner-container .contents {
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .c-hero-component.farah-fullwidth.right .hero-banner-container .contents:before {
    left: auto;
    right: 0;
  }
}
.c-hero-component.farah-fullwidth.light .contents .title h3,
.c-hero-component.farah-fullwidth.light .hero-banner-title,
.c-hero-component.farah-fullwidth.light .hero-banner-desc,
.c-hero-component.farah-fullwidth.light .hero-banner-shortTitle {
  color: #ffffff;
}
.c-hero-component.farah-fullwidth.dark .contents .title h3,
.c-hero-component.farah-fullwidth.dark .hero-banner-title,
.c-hero-component.farah-fullwidth.dark .hero-banner-desc,
.c-hero-component.farah-fullwidth.dark .hero-banner-shortTitle {
  color: #404040;
}
.c-hero-component .editorial-text.from-hero-component-container {
  margin-top: 30px;
}
.c-hero-component .editorial-text.from-hero-component-container .c-editorial-text {
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .c-hero-component .editorial-text.from-hero-component-container .c-editorial-text {
    padding-top: 0px;
  }
}
.c-hero-component .editorial-text.from-hero-component-container .c-editorial-text .w--content.from-hero-component-container > h2:before,
.c-hero-component .editorial-text.from-hero-component-container .c-editorial-text .w--content.from-hero-component-container > h1:before {
  content: "";
  display: block;
  border-left: 2px solid;
  height: 24px;
  margin-left: 50%;
  margin-bottom: 5px;
}
.c-hero-component .editorial-text.from-hero-component-container .c-editorial-text .w--content.from-hero-component-container > h2:before,
.c-hero-component .editorial-text.from-hero-component-container .c-editorial-text .w--content.from-hero-component-container > h1:before {
  border-left-color: #0073cd;
}

.tenant-yww .c-hero-component.v-annual-pass-hero .hero-detail-container .hero-banner-shortTitle {
  padding-top: 8px;
  padding-bottom: 0;
  font-weight: normal;
  letter-spacing: 2px;
  line-height: 1.6;
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .tenant-yww .c-hero-component.v-annual-pass-hero .hero-detail-container .hero-banner-shortTitle {
    letter-spacing: 1.5px;
    line-height: 1.8;
  }
}
.tenant-yww .c-hero-component.v-annual-pass-hero .hero-detail-container .hero-banner-shortTitle > div {
  font-family: "Amaranth", "Open Sans", Arial, sans-serif;
  font-weight: 700;
  font-size: 30px;
  font-size: 1.875rem;
  letter-spacing: 0;
  line-height: 1.27;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  .tenant-yww .c-hero-component.v-annual-pass-hero .hero-detail-container .hero-banner-shortTitle > div {
    font-size: 30px;
    font-size: 1.875rem;
  }
}
@media only screen and (min-width: 1024px) {
  .tenant-yww .c-hero-component.v-annual-pass-hero .hero-detail-container .hero-banner-shortTitle > div {
    font-size: 40px;
    font-size: 2.5rem;
    line-height: 1.15;
  }
}
@media only screen and (min-width: 1366px) {
  .tenant-yww .c-hero-component.v-annual-pass-hero .hero-detail-container .hero-banner-shortTitle > div {
    font-size: 50px;
    font-size: 3.125rem;
    line-height: 1.16;
  }
}

/*Editorial Tooltip Overlay on smart hero*/
.ride-info-overlay .overlay-wrapper {
  text-align: center;
  overflow: auto;
  padding: 50px 20px;
  display: block;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
}
@media (min-width: 768px) {
  .ride-info-overlay .overlay-wrapper {
    padding: 80px;
  }
}
@media only screen and (min-width: 1024px) {
  .ride-info-overlay .overlay-wrapper {
    padding: 50px 80px;
    max-height: 500px;
    min-height: auto;
  }
}
.ride-info-overlay .overlay-wrapper::-webkit-scrollbar {
  width: 0;
}
.ride-info-overlay .overlay-wrapper div:not(.brand-logo).brand-logo-container {
  width: auto;
  display: none;
}
.ride-info-overlay .overlay-wrapper div.overlay--close-button {
  height: auto;
  right: 20px;
  top: 20px;
  width: auto;
  padding: 0;
}
.ride-info-overlay .overlay-wrapper div.overlay--close-button svg {
  width: 20px;
  height: 20px;
}
.ride-info-overlay .overlay-wrapper div.overlay--close-button svg use.svg-use {
  fill: #0073cd;
  stroke: #0073cd;
}
.ride-info-overlay .overlay-wrapper:before {
  background-color: #f6f6f6;
  content: "";
  border-radius: 0 0 90px 90px;
  display: block;
  height: 18px;
  width: 24px;
  top: -20px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.ride-info-overlay .overlay-wrapper h1 {
  padding-bottom: 15px;
  padding-top: 20px;
}
.ride-info-overlay .overlay-wrapper h2 {
  padding-bottom: 20px;
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 800;
  letter-spacing: 1.2px;
  line-height: 1.22;
  font-size: 18px;
  font-size: 1.125rem;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
  /* Small devices (tablets, 768px and up) */
}
@media (min-width: 768px) {
  .ride-info-overlay .overlay-wrapper h2 {
    font-size: 18px;
    font-size: 1.125rem;
  }
}
@media only screen and (min-width: 1024px) {
  .ride-info-overlay .overlay-wrapper h2 {
    font-size: 18px;
    font-size: 1.125rem;
  }
}
@media only screen and (min-width: 1366px) {
  .ride-info-overlay .overlay-wrapper h2 {
    font-size: 18px;
    font-size: 1.125rem;
  }
}
@media (min-width: 768px) {
  .ride-info-overlay .overlay-wrapper h2 {
    padding-bottom: 10px;
  }
}
.ride-info-overlay .overlay-wrapper h2.overlay-sub-heading {
  left: 0;
  position: fixed;
  top: 30px;
  width: 100%;
  background: #ffffff;
}
.lang-ar .ride-info-overlay .overlay-wrapper h2.overlay-sub-heading {
  right: 0;
  left: auto;
  left: auto;
}
.ride-info-overlay .overlay-wrapper ul {
  text-align: left;
  padding-left: 15px;
  padding-right: 15px;
}
.ride-info-overlay .overlay-wrapper p {
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 400;
  line-height: 1.71;
  letter-spacing: 0.7px;
  font-size: 14px;
  font-size: 0.875rem;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
  padding-bottom: 20px;
  text-align: left;
}
@media (min-width: 768px) {
  .ride-info-overlay .overlay-wrapper p {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
@media only screen and (min-width: 1024px) {
  .ride-info-overlay .overlay-wrapper p {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
@media only screen and (min-width: 1366px) {
  .ride-info-overlay .overlay-wrapper p {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
.ride-info-overlay .overlay-wrapper .overlay-description {
  max-width: 100%;
  padding: 20px 0 0;
}
.ride-info-overlay .overlay-wrapper .overlay-description li {
  margin-left: 20px;
}
.ride-info-overlay .overlay-container {
  height: 100%;
}
.ride-info-overlay h3 {
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 400;
  line-height: 1.63;
  letter-spacing: 0.8px;
  font-size: 16px;
  font-size: 1rem;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  .ride-info-overlay h3 {
    font-size: 16px;
    font-size: 1rem;
  }
}
@media only screen and (min-width: 1024px) {
  .ride-info-overlay h3 {
    font-size: 18px;
    font-size: 1.125rem;
    line-height: 1.56;
    letter-spacing: 0.9px;
  }
}
@media only screen and (min-width: 1366px) {
  .ride-info-overlay h3 {
    font-size: 18px;
    font-size: 1.125rem;
    line-height: 1.56;
    letter-spacing: 0.9px;
  }
}
.lang-ar .ride-info-overlay h3 {
  font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
  font-weight: 400;
  line-height: 1.63;
  letter-spacing: 0.8px;
  font-size: 16px;
  font-size: 1rem;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  .lang-ar .ride-info-overlay h3 {
    font-size: 16px;
    font-size: 1rem;
  }
}
@media only screen and (min-width: 1024px) {
  .lang-ar .ride-info-overlay h3 {
    font-size: 18px;
    font-size: 1.125rem;
    line-height: 1.56;
    letter-spacing: 0.9px;
  }
}
@media only screen and (min-width: 1366px) {
  .lang-ar .ride-info-overlay h3 {
    font-size: 18px;
    font-size: 1.125rem;
    line-height: 1.56;
    letter-spacing: 0.9px;
  }
}
.ride-info-overlay .w--middle {
  width: 100%;
}

.c-hero-component.component.hero-card.component {
  margin-bottom: 20px;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
}
@media (min-width: 768px) {
  .c-hero-component.component.hero-card.component {
    margin-bottom: 35px;
  }
}
@media only screen and (min-width: 1024px) {
  .c-hero-component.component.hero-card.component {
    margin-bottom: 10px;
  }
  .home-page .c-hero-component.component.hero-card.component {
    margin-bottom: 60px;
  }
}
.c-hero-component.component.hero-card.component .c-media-carousel-wrapper.component {
  margin-bottom: 30px;
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .c-hero-component.component.hero-card.component .c-media-carousel-wrapper.component {
    margin-bottom: 0;
  }
}
.c-hero-component.component.hero-card.component .c-media-carousel-wrapper.component .c-media-carousel.component {
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
}
@media (min-width: 768px) {
  .c-hero-component.component.hero-card.component .c-media-carousel-wrapper.component .c-media-carousel.component {
    margin-bottom: 0;
  }
}
@media only screen and (min-width: 1024px) {
  .c-hero-component.component.hero-card.component .c-media-carousel-wrapper.component .c-media-carousel.component {
    margin-bottom: 40px;
  }
}
.c-hero-component.component.hero-card.component .hero-banner-container {
  margin-bottom: 30px;
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .c-hero-component.component.hero-card.component .hero-banner-container {
    margin-bottom: 40px;
  }
}
.c-hero-component.component.dom-rendering {
  /* < 1023px */
}
@media (min-width: 320px) and (max-width: 1024px) {
  .c-hero-component.component.dom-rendering .c-media-carousel-wrapper {
    background-color: #0073cd;
  }
  .c-hero-component.component.dom-rendering .c-media-carousel-wrapper .media-carousel--content .w-media-content {
    margin-bottom: 73px;
  }
}
.c-hero-component.component .c-media-carousel-wrapper .media-carousel--content .c-media-carousel .w--middle-mc.dom-pending .w--content .c-media-carousel-view-container .slick-initialized {
  /* < 768px */
  /* Small devices (tablets, 768px till 1023px) */
}
@media (min-width: 320px) and (max-width: 767px) {
  .c-hero-component.component .c-media-carousel-wrapper .media-carousel--content .c-media-carousel .w--middle-mc.dom-pending .w--content .c-media-carousel-view-container .slick-initialized {
    background-color: #0073cd;
    padding-bottom: 20px;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .c-hero-component.component .c-media-carousel-wrapper .media-carousel--content .c-media-carousel .w--middle-mc.dom-pending .w--content .c-media-carousel-view-container .slick-initialized {
    background-color: #0073cd;
    padding-bottom: 20px;
  }
}
.c-hero-component.component .c-media-carousel-wrapper .media-carousel--content .c-media-carousel .w--middle-mc.dom-pending .w--content .c-media-carousel-view-container .slick-initialized .slick-list .slick-track {
  display: flex;
}
.c-hero-component.component .c-media-carousel-wrapper .media-carousel--content .c-media-carousel .w--middle-mc.dom-pending .w--content .c-media-carousel-view-container .slick-initialized .slick-list .slick-track .slick-slide {
  min-width: 100vw;
}
.c-hero-component.component .c-media-carousel-wrapper .media-carousel--content .c-media-carousel .w--middle-mc.dom-pending .w--content .c-media-carousel-view-container .slick-initialized .slick-list .slick-track .slick-slide[data-index="-1"] {
  display: none;
}
.c-hero-component.component .c-media-carousel-wrapper .media-carousel--content .c-media-carousel .w--middle-mc .w--content .c-media-carousel-view-container .slick-initialized .slick-list .slick-track .slick-slide {
  /* < 768px */
  /* Small devices (tablets, 768px till 1023px) */
}
@media (min-width: 320px) and (max-width: 767px) {
  .c-hero-component.component .c-media-carousel-wrapper .media-carousel--content .c-media-carousel .w--middle-mc .w--content .c-media-carousel-view-container .slick-initialized .slick-list .slick-track .slick-slide {
    min-width: 100%;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .c-hero-component.component .c-media-carousel-wrapper .media-carousel--content .c-media-carousel .w--middle-mc .w--content .c-media-carousel-view-container .slick-initialized .slick-list .slick-track .slick-slide {
    min-width: 100%;
  }
}
.c-hero-component.component .c-media-carousel-wrapper .media-carousel--content .c-media-carousel .w--middle-mc .w--content .c-media-carousel-view-container .slick-initialized .slick-list .slick-track .slick-slide .media-carousel-item .media-carousel-item--video {
  padding: 0;
}
.c-hero-component.component .c-media-carousel-wrapper .media-carousel--content .c-media-carousel .w--middle-mc .w--content .c-media-carousel-view-container .slick-initialized .slick-list .slick-track .slick-slide .media-carousel-item .media-carousel-item--video .c-video {
  margin: 0;
}
.c-hero-component.component .c-media-carousel-wrapper .media-carousel--content .c-media-carousel .w--middle-mc .w--content .c-media-carousel-view-container .slick-initialized .slick-list .slick-track .slick-slide .media-carousel-item .media-desc-container {
  /* Small devices (tablets, 768px till 1023px) */
  /* < 768px */
}
@media (min-width: 768px) and (max-width: 1023px) {
  .c-hero-component.component .c-media-carousel-wrapper .media-carousel--content .c-media-carousel .w--middle-mc .w--content .c-media-carousel-view-container .slick-initialized .slick-list .slick-track .slick-slide .media-carousel-item .media-desc-container {
    width: 100%;
    background-color: #0073cd;
    max-width: 100%;
    padding: 18px 15px 32px;
  }
}
@media (min-width: 320px) and (max-width: 767px) {
  .c-hero-component.component .c-media-carousel-wrapper .media-carousel--content .c-media-carousel .w--middle-mc .w--content .c-media-carousel-view-container .slick-initialized .slick-list .slick-track .slick-slide .media-carousel-item .media-desc-container {
    width: 100%;
    background-color: #0073cd;
    max-width: 100%;
    padding: 18px 15px 32px;
  }
}
.c-hero-component.component .c-media-carousel-wrapper .media-carousel--content .c-media-carousel .w--middle-mc .w--content .c-media-carousel-view-container .slick-initialized .slick-list .slick-track .slick-slide .media-carousel-item .media-desc-container h1.media-carousel-item--title {
  font-family: Amaranth-Onload, Open Sans, Arial, sans-serif;
  font-size: 2.5rem;
  /* Small devices (tablets, 768px till 1023px) */
  /* < 768px */
}
@media (min-width: 768px) and (max-width: 1023px) {
  .c-hero-component.component .c-media-carousel-wrapper .media-carousel--content .c-media-carousel .w--middle-mc .w--content .c-media-carousel-view-container .slick-initialized .slick-list .slick-track .slick-slide .media-carousel-item .media-desc-container h1.media-carousel-item--title {
    color: #ffffff;
    font-size: 2.5rem;
    line-height: 46px;
    padding: 0;
    font-weight: bold;
    letter-spacing: 0;
  }
}
@media (min-width: 320px) and (max-width: 767px) {
  .c-hero-component.component .c-media-carousel-wrapper .media-carousel--content .c-media-carousel .w--middle-mc .w--content .c-media-carousel-view-container .slick-initialized .slick-list .slick-track .slick-slide .media-carousel-item .media-desc-container h1.media-carousel-item--title {
    color: #ffffff;
    font-size: 2.5rem;
    line-height: 46px;
    padding: 0;
    font-weight: bold;
    letter-spacing: 0;
  }
}
.lang-ar .c-hero-component.component .c-media-carousel-wrapper .media-carousel--content .c-media-carousel .w--middle-mc .w--content .c-media-carousel-view-container .slick-initialized .slick-list .slick-track .slick-slide .media-carousel-item .media-desc-container h1.media-carousel-item--title {
  font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
}
.c-hero-component.component .c-media-carousel-wrapper .media-carousel--content .c-media-carousel .w--middle-mc .w--content .c-media-carousel-view-container .slick-initialized .slick-list .slick-track .slick-slide .media-carousel-item .media-desc-container div.media-carousel-item--bodycopy {
  font-family: Amaranth-Onload, Open Sans, Arial, sans-serif;
  /* Small devices (tablets, 768px till 1023px) */
  /* < 768px */
}
@media (min-width: 768px) and (max-width: 1023px) {
  .c-hero-component.component .c-media-carousel-wrapper .media-carousel--content .c-media-carousel .w--middle-mc .w--content .c-media-carousel-view-container .slick-initialized .slick-list .slick-track .slick-slide .media-carousel-item .media-desc-container div.media-carousel-item--bodycopy {
    color: #ffffff;
    font-family: "Open Sans", Arial, sans-serif;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 38px;
  }
}
@media (min-width: 320px) and (max-width: 767px) {
  .c-hero-component.component .c-media-carousel-wrapper .media-carousel--content .c-media-carousel .w--middle-mc .w--content .c-media-carousel-view-container .slick-initialized .slick-list .slick-track .slick-slide .media-carousel-item .media-desc-container div.media-carousel-item--bodycopy {
    color: #ffffff;
    font-family: "Open Sans", Arial, sans-serif;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 38px;
  }
}
.lang-ar .c-hero-component.component .c-media-carousel-wrapper .media-carousel--content .c-media-carousel .w--middle-mc .w--content .c-media-carousel-view-container .slick-initialized .slick-list .slick-track .slick-slide .media-carousel-item .media-desc-container div.media-carousel-item--bodycopy {
  font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
}
.c-hero-component.component .c-media-carousel-wrapper .media-carousel--content .c-media-carousel .w--middle-mc .w--content .c-media-carousel-view-container .slick-initialized .slick-list .slick-track .slick-slide .media-carousel-item .media-desc-container .btn-primary a {
  background-color: #0073cd;
  border-color: #0073cd;
  color: #ffffff;
  /* Small devices (tablets, 768px till 1023px) */
  /* < 768px */
}
@media (min-width: 768px) and (max-width: 1023px) {
  .c-hero-component.component .c-media-carousel-wrapper .media-carousel--content .c-media-carousel .w--middle-mc .w--content .c-media-carousel-view-container .slick-initialized .slick-list .slick-track .slick-slide .media-carousel-item .media-desc-container .btn-primary a {
    background-color: #ffffff;
    border-color: #ffffff;
    color: #0073cd;
    font-size: 14px;
    font-weight: 800;
    letter-spacing: 1px;
    line-height: 18px;
  }
}
@media (min-width: 320px) and (max-width: 767px) {
  .c-hero-component.component .c-media-carousel-wrapper .media-carousel--content .c-media-carousel .w--middle-mc .w--content .c-media-carousel-view-container .slick-initialized .slick-list .slick-track .slick-slide .media-carousel-item .media-desc-container .btn-primary a {
    background-color: #ffffff;
    border-color: #ffffff;
    color: #0073cd;
    font-size: 14px;
    font-weight: 800;
    letter-spacing: 1px;
    line-height: 18px;
  }
}
.c-hero-component.component .c-media-carousel-wrapper .media-carousel--content .c-media-carousel .w--middle-mc .w--content .c-media-carousel-view-container .slick-initialized .slick-list .slick-track .slick-slide .media-carousel-item .media-desc-container .btn-primary button {
  /* Small devices (tablets, 768px till 1023px) */
  /* < 768px */
}
@media (min-width: 768px) and (max-width: 1023px) {
  .c-hero-component.component .c-media-carousel-wrapper .media-carousel--content .c-media-carousel .w--middle-mc .w--content .c-media-carousel-view-container .slick-initialized .slick-list .slick-track .slick-slide .media-carousel-item .media-desc-container .btn-primary button {
    background-color: #ffffff;
    border-color: #ffffff;
    color: #0073cd;
  }
}
@media (min-width: 320px) and (max-width: 767px) {
  .c-hero-component.component .c-media-carousel-wrapper .media-carousel--content .c-media-carousel .w--middle-mc .w--content .c-media-carousel-view-container .slick-initialized .slick-list .slick-track .slick-slide .media-carousel-item .media-desc-container .btn-primary button {
    background-color: #ffffff;
    border-color: #ffffff;
    color: #0073cd;
  }
}
.c-hero-component.component > .hero-banner-container .quick-links {
  bottom: 10px;
}
.c-hero-component.component .c-media-carousel-wrapper .media-carousel--content {
  position: relative;
}
.c-hero-component.component .c-media-carousel-wrapper .media-carousel--content .quick-links {
  bottom: 75px;
}
.c-hero-component.component .quick-links {
  position: absolute;
  text-align: center;
  width: 100%;
  z-index: 1;
  /* < 768px */
}
@media (min-width: 320px) and (max-width: 767px) {
  .c-hero-component.component .quick-links {
    position: relative;
    padding: 10px;
    background-color: #0073cd;
    top: -30px;
    margin-bottom: -30px;
  }
}
.c-hero-component.component .quick-links .quick-links-band {
  background: #ffffff;
  border-color: #d3d3d3;
}
.c-hero-component.component .quick-links .quick-links-band-item a {
  text-decoration: none;
}
.c-hero-component.component .quick-links .quick-links-band-item__link {
  padding: 0 1em !important;
  /* < 768px */
}
@media (min-width: 320px) and (max-width: 767px) {
  .c-hero-component.component .quick-links .quick-links-band-item__link {
    padding: 0 10px !important;
  }
}
.c-hero-component.component .quick-links .quick-links-band-item__link:hover {
  background: rgba(0, 0, 0, 0.05) !important;
}
.c-hero-component.component .quick-links .quick-links-band-item__link__content__text span.ldc-custom {
  font-family: Amaranth-Onload, Open Sans, Arial, sans-serif;
  color: #404040;
  font-size: 28px;
  line-height: 32px;
  letter-spacing: normal;
  font-weight: 700;
  font-style: normal;
  /* < 768px */
}
@media (min-width: 320px) and (max-width: 767px) {
  .c-hero-component.component .quick-links .quick-links-band-item__link__content__text span.ldc-custom {
    font-size: 24px;
    line-height: 24px;
  }
}
.lang-ar .c-hero-component.component .quick-links .quick-links-band-item__link__content__text span.ldc-custom {
  font-family: Noto-Kufi-Arabic-Onload, Open Sans, Arial, sans-serif;
  font-weight: 700;
  line-height: 40px;
  /* < 768px */
}
@media (min-width: 320px) and (max-width: 767px) {
  .lang-ar .c-hero-component.component .quick-links .quick-links-band-item__link__content__text span.ldc-custom {
    line-height: 30px;
  }
}
.c-hero-component.component .quick-links .quick-links-band-item__link__content__text span.short-title {
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 400;
  color: #404040;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.1px;
  padding-top: 0;
}
.lang-ar .c-hero-component.component .quick-links .quick-links-band-item__link__content__text span.short-title {
  font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 27px;
  letter-spacing: normal;
  /* < 768px */
}
@media (min-width: 320px) and (max-width: 767px) {
  .lang-ar .c-hero-component.component .quick-links .quick-links-band-item__link__content__text span.short-title {
    font-size: 16px;
    line-height: 30px;
  }
}

.lang-ar .ride-info-overlay .overlay-wrapper .overlay-content-inner-container {
  left: auto;
  right: -22px;
}
.lang-ar .ride-info-overlay .overlay-wrapper ul,
.lang-ar .ride-info-overlay .overlay-wrapper p {
  text-align: right;
}
.lang-ar .ride-info-overlay .overlay-wrapper .overlay-description li {
  margin-right: 20px;
}
.lang-ar .c-hero-component .hero-banner-title {
  font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1.5;
  font-size: 12px;
  font-size: 0.75rem;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  .lang-ar .c-hero-component .hero-banner-title {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
@media only screen and (min-width: 1024px) {
  .lang-ar .c-hero-component .hero-banner-title {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
@media only screen and (min-width: 1366px) {
  .lang-ar .c-hero-component .hero-banner-title {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
.lang-ar .c-hero-component .hero-banner-desc h1 {
  font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
  font-weight: 700;
  font-size: 24px;
  font-size: 1.5rem;
  letter-spacing: 0;
  line-height: 1.42;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  .lang-ar .c-hero-component .hero-banner-desc h1 {
    font-size: 24px;
    font-size: 1.5rem;
  }
}
@media only screen and (min-width: 1024px) {
  .lang-ar .c-hero-component .hero-banner-desc h1 {
    font-size: 24px;
    font-size: 1.5rem;
  }
}
@media only screen and (min-width: 1366px) {
  .lang-ar .c-hero-component .hero-banner-desc h1 {
    font-size: 36px;
    font-size: 2.25rem;
    line-height: 1.28;
  }
}
.lang-ar .c-hero-component .hero-banner-desc p {
  font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
  font-weight: 400;
  line-height: 1.85;
  letter-spacing: 0.7px;
  font-size: 13px;
  font-size: 0.8125rem;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
  width: auto;
  -ms-word-wrap: break-word;
  word-wrap: break-word;
}
@media (min-width: 768px) {
  .lang-ar .c-hero-component .hero-banner-desc p {
    font-size: 13px;
    font-size: 0.8125rem;
  }
}
@media only screen and (min-width: 1024px) {
  .lang-ar .c-hero-component .hero-banner-desc p {
    font-size: 16px;
    font-size: 1rem;
    line-height: 1.63;
    letter-spacing: 0.8px;
  }
}
@media only screen and (min-width: 1366px) {
  .lang-ar .c-hero-component .hero-banner-desc p {
    font-size: 16px;
    font-size: 1rem;
  }
}
.lang-ar .c-hero-component .hero-detail-container .hero-banner-shortTitle {
  font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
  font-weight: 700;
  letter-spacing: 1px;
  line-height: 1.43;
  font-size: 14px;
  font-size: 0.875rem;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  .lang-ar .c-hero-component .hero-detail-container .hero-banner-shortTitle {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
@media only screen and (min-width: 1024px) {
  .lang-ar .c-hero-component .hero-detail-container .hero-banner-shortTitle {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
@media only screen and (min-width: 1366px) {
  .lang-ar .c-hero-component .hero-detail-container .hero-banner-shortTitle {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
.lang-ar .c-hero-component .hero-detail-container .hero-banner-title {
  font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
  font-weight: 700;
  font-size: 24px;
  font-size: 1.5rem;
  letter-spacing: 0;
  line-height: 1.42;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  .lang-ar .c-hero-component .hero-detail-container .hero-banner-title {
    font-size: 24px;
    font-size: 1.5rem;
  }
}
@media only screen and (min-width: 1024px) {
  .lang-ar .c-hero-component .hero-detail-container .hero-banner-title {
    font-size: 36px;
    font-size: 2.25rem;
    line-height: 1.28;
  }
}
@media only screen and (min-width: 1366px) {
  .lang-ar .c-hero-component .hero-detail-container .hero-banner-title {
    font-size: 46px;
    font-size: 2.875rem;
    line-height: 1.26;
  }
}
.lang-ar .c-hero-component .hero-detail-container .hero-banner-desc {
  font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
  font-weight: 400;
  line-height: 1.85;
  letter-spacing: 0.7px;
  font-size: 13px;
  font-size: 0.8125rem;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
  width: 100%;
}
@media (min-width: 768px) {
  .lang-ar .c-hero-component .hero-detail-container .hero-banner-desc {
    font-size: 13px;
    font-size: 0.8125rem;
  }
}
@media only screen and (min-width: 1024px) {
  .lang-ar .c-hero-component .hero-detail-container .hero-banner-desc {
    font-size: 16px;
    font-size: 1rem;
    line-height: 1.63;
    letter-spacing: 0.8px;
  }
}
@media only screen and (min-width: 1366px) {
  .lang-ar .c-hero-component .hero-detail-container .hero-banner-desc {
    font-size: 16px;
    font-size: 1rem;
  }
}
.lang-ar .c-hero-component .hero-detail-container .hero-banner-desc > .btn-primary {
  /* Small devices (tablets, 768px and up) */
}
@media (min-width: 768px) {
  .lang-ar .c-hero-component .hero-detail-container .hero-banner-desc > .btn-primary {
    text-align: right;
  }
}
.lang-ar .c-hero-component.right .hero-image {
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media only screen and (min-width: 1024px) {
  .lang-ar .c-hero-component.right .hero-image {
    width: 64.8484848485%;
    max-width: 885.8303030303px;
    margin-left: 0.9090909091%;
    margin-right: 0.9090909091%;
    margin: 0;
  }
  .lang-ar .c-hero-component.right .hero-image:before {
    background-image: url(../../../assets/img/watercolour-v-1-left-tablet.png);
    content: "";
    width: 476px;
    height: 100%;
    position: absolute;
    left: -358px;
    background-repeat: no-repeat;
    background-position: 47px 22px;
    background-size: contain;
    z-index: -1;
  }
  .lang-ar .c-hero-component.right .hero-image:after {
    content: none;
  }
}
@media only screen and (min-width: 1366px) {
  .lang-ar .c-hero-component.right .hero-image:before {
    background-image: url(../../../assets/img/watercolour-v-1-right.png);
    width: 571px;
    top: 0;
    background-position: right top;
    right: -170px;
    left: auto;
  }
  .lang-ar .c-hero-component.right .hero-image:after {
    background-image: url(../../../assets/img/watercolour-v-1-left.png);
    content: "";
    width: 476px;
    left: -474px;
    right: auto;
    background-position: right top;
  }
}
.lang-ar .c-hero-component.right .hero-image picture:before {
  transform: none;
}
.lang-ar .c-hero-component.right .hero-detail-container {
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .lang-ar .c-hero-component.right .hero-detail-container {
    transform: translateX(40%);
    margin: 0 -100px 0 0;
  }
}
.lang-ar .c-hero-component.left .hero-image {
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .lang-ar .c-hero-component.left .hero-image:before {
    background: url(../../../assets/img/watercolour-v-2-right.png) left top/contain no-repeat;
    content: "";
    height: 100%;
    max-height: 400px;
    max-width: 476px;
    width: 100%;
    position: absolute;
    right: auto;
    left: 100%;
    z-index: -1;
  }
  .lang-ar .c-hero-component.left .hero-image:after {
    content: none;
  }
}
.lang-ar .c-hero-component.left .hero-image picture:before {
  content: none;
}
.lang-ar .c-hero-component.left .hero-detail-container {
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .lang-ar .c-hero-component.left .hero-detail-container {
    transform: translateX(-50%);
    margin: 0;
  }
}
.lang-ar .c-hero-component.article-detail-hero .w--content .body-copy-1 {
  text-align: right;
}
.lang-ar .c-hero-component.left-splash .editorial-text .w--content:first-child {
  position: relative;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
}
.lang-ar .c-hero-component.left-splash .editorial-text .w--content:first-child:after {
  right: -9px;
  left: auto;
  transform: scaleX(1);
}
@media (min-width: 768px) {
  .lang-ar .c-hero-component.left-splash .editorial-text .w--content:first-child:after {
    right: -10px;
    left: auto;
  }
}
@media only screen and (min-width: 1024px) {
  .lang-ar .c-hero-component.left-splash .editorial-text .w--content:first-child:after {
    right: -170px;
    left: auto;
  }
}
.lang-ar .c-hero-component.right-splash .editorial-text .w--content:first-child {
  position: relative;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
}
.lang-ar .c-hero-component.right-splash .editorial-text .w--content:first-child:after {
  left: -9px;
  right: auto;
}
@media (min-width: 768px) {
  .lang-ar .c-hero-component.right-splash .editorial-text .w--content:first-child:after {
    left: -10px;
    right: auto;
  }
}
@media only screen and (min-width: 1024px) {
  .lang-ar .c-hero-component.right-splash .editorial-text .w--content:first-child:after {
    top: 0;
    left: -170px;
    right: auto;
  }
}
.lang-ar .c-hero-component.both-splash .editorial-text {
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
}
.lang-ar .c-hero-component.both-splash .editorial-text:before {
  right: 0;
  left: auto;
  transform: scaleX(1);
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .lang-ar .c-hero-component.both-splash .editorial-text:before {
    transform: scaleX(-1);
  }
}
.lang-ar .c-hero-component.both-splash .editorial-text:after {
  left: 0;
  right: auto;
  transform: scaleX(-1);
}
@media (min-width: 768px) {
  .lang-ar .c-hero-component.both-splash .editorial-text:before {
    right: -10px;
    left: auto;
  }
  .lang-ar .c-hero-component.both-splash .editorial-text:after {
    left: -10px;
    right: auto;
  }
}
@media only screen and (min-width: 1024px) {
  .lang-ar .c-hero-component.both-splash .editorial-text:before {
    right: 0;
    left: auto;
  }
  .lang-ar .c-hero-component.both-splash .editorial-text:after {
    left: 0;
    right: auto;
  }
}
.lang-ar .c-hero-component .c-editorial-grid-tooltip {
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .lang-ar .c-hero-component .c-editorial-grid-tooltip {
    border-right: none;
    border-left: 1px dotted #d3d0d0;
  }
  .lang-ar .c-hero-component .c-editorial-grid-tooltip:last-of-type {
    border: none;
  }
}
.lang-ar .c-hero-component .c-editorial-grid-tooltip .product-tooltip-body ul {
  margin-left: 0;
  margin-right: 18px;
  text-align: right;
}
.lang-ar .c-hero-component .c-editorial-grid-tooltip .product-tooltip .close-tooltip {
  right: auto;
  left: 12px;
}
.lang-ar.legal-page .c-hero-component .hero-banner-container {
  margin-bottom: 55px;
}
.lang-ar .ride-info-overlay .overlay-wrapper div.overlay--close-button {
  right: auto;
  left: 20px;
}
.lang-ar .ride-info-overlay .overlay-wrapper ul {
  text-align: right;
}

.lang-ru .c-hero-component .hero-banner-desc h1 {
  font-family: "robotobold", "Open Sans", Arial, sans-serif;
  font-size: 28px;
  font-size: 1.75rem;
  letter-spacing: 0;
  line-height: 1.1;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  .lang-ru .c-hero-component .hero-banner-desc h1 {
    font-size: 28px;
    font-size: 1.75rem;
    line-height: 1.2;
  }
}
@media only screen and (min-width: 1024px) {
  .lang-ru .c-hero-component .hero-banner-desc h1 {
    font-size: 30px;
    font-size: 1.875rem;
    line-height: 1.2;
  }
}
@media only screen and (min-width: 1366px) {
  .lang-ru .c-hero-component .hero-banner-desc h1 {
    font-size: 40px;
    font-size: 2.5rem;
    line-height: 1.2;
  }
}
.lang-ru .c-hero-component .hero-detail-container .hero-banner-title {
  font-family: "robotobold", "Open Sans", Arial, sans-serif;
  font-size: 30px;
  font-size: 1.875rem;
  letter-spacing: 0;
  line-height: 1.12;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  .lang-ru .c-hero-component .hero-detail-container .hero-banner-title {
    font-size: 30px;
    font-size: 1.875rem;
    line-height: 1.1;
  }
}
@media only screen and (min-width: 1024px) {
  .lang-ru .c-hero-component .hero-detail-container .hero-banner-title {
    font-size: 40px;
    font-size: 2.5rem;
    line-height: 1.2;
  }
}
@media only screen and (min-width: 1366px) {
  .lang-ru .c-hero-component .hero-detail-container .hero-banner-title {
    font-size: 50px;
    font-size: 3.125rem;
    line-height: 1.2;
  }
}

/*
 * Config
 * @ Variables
 *
*/
/*calendar dropdown*/
/*
 *  Radius
*/
/*
 *  Use Defaults
*/
/*
 *  YWW base Font size
*/
/*
 * H1
*/
/*
 * H2
*/
/*
 * H3
*/
/*
 * H4
*/
/*
 * H5
*/
/*
 * H6
*/
/*
 * H7
*/
/*
 * H8
*/
/*
 * H9
*/
/*
 * H10
*/
/*
 * body-1
*/
/*
 * body-2
*/
/*
 * body-3
*/
/*
 * body-4
*/
/*
 * body-5
*/
/*
 * body-6
*/
/*
 * H1
*/
/*
 * H2
*/
/*
 * H3
*/
/*
 * H4
*/
/*
 * H5
*/
/*
 * H6
*/
/*
 * H7
*/
/*
 * body-1
*/
/*
 * body-2
*/
/*
 * body-3
*/
/*
 * body-4
*/
/*
 * body-5
*/
/*
 * body-6
*/
/* media carousel overlay */
/*
 * H1
*/
/*
 * H2
*/
/*
 * H3
*/
/*
 * H4
*/
/*
* form placeholder variables
*/
/*
 * Base
 * @ Colors
 *
*/
/* Colors */
/* Trip Advisor Color */
/* Guest checkout Color */
/*
 * Line height font
 */
/*
 * Border Radius
 */
/*
 * Border Radius Separate
 */
/*
 * Box Shadow
 */
/*
 * Widescreen
 */
/*
 * Desktop (PC's)
 */
/*
 * Wide screen Desktop (PC's)
 */
/*
 * Extra Wide screen Desktop (PC's)
 */
/*
 * Tablet
 */
/*
  * TabletOnly
  */
/*
 * upto Tablet
 */
/*
 * Tablet2
 */
/*
 * Mobile
 */
/*
 * No Screen
 */
/*
 * Transition
    usage example
      a{
      color: gray;
      @include transition(color .3s ease);
      &:hover {
        color: black;
      }
}
 */
/*
 * Opacity
 */
/*
 * Clearfix
 */
/*
 * Configure vendor
 */
/*
 * SVG stroke and fill
 */
/*
 * Transform
 */
/**
*   Use Arrow inside the :after and :before pseudo elements
*   and change the position as per needed
*   @include arrows('right', 7px, 7px, #000);
**/
/*
 * Flex
 */
/*
 * New Design System - 2025
 *
*/
/*
* Breakpoint
* @ Variables
*
*/
/*
    i/p -
        $available-maxwidth : maximum available width
        $column-width : width of 1 column
        $col-count : column no who's width has to be calculated
    o/p -
        column width of desired column no in %
    example -
        $available-max-width = 13200 , $column-width = 12
        avl width = 1320px = 100%
        width of 1px in % = 100 *(1 / 1320px)
        1 col-width in % = col-width * width of 1px in %
        86px(col-width) in % = 86 * (100 *(1 / 1320px)) = 6.51515%
*/
/*
    o/p -
        column width of desired column no in %
    i/p -
        $breakpoint : viewport at which width needs to be calculated
        $col-count : column no who's width has to be calculated
*/
/*
    o/p -
        returns value of gutter for 1 column in % as per the viewport
    i/p -
        $breakpoint : breakpoint at which width needs to be calculated
*/
/*
    o/p -
        returns
            $width: width of column w.r.t col-count
            $margin: margin-left/right for a column
    i/p -
        $breakpoint : breakpoint at which width needs to be calculated
        $col-count : value of column who's width has to be calculated
*/
/*
    o/p -
        returns
            $margin: margin-left/right for a column
    i/p -
        $breakpoint : breakpoint at which width needs to be calculated
*/
/*
    o/p -
        returns
            margin-left: left margin = width of 2 columns + gutter
    i/p -
        $breakpoint : breakpoint at which width needs to be calculated
        $col-count : value of column who's width has to be calculated
*/
/*
 * Base
 * @ Text
 *
*/
/*
 * Base
 * @ Gradients
 *
*/
/*
 * Line height font
 */
.hero-panel-with-card.component {
  margin-top: 30px;
  margin-bottom: 0;
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .hero-panel-with-card.component {
    margin-top: 0;
  }
}
.hero-panel-with-card.hero-panel-contain-card .hero-card-container {
  max-width: 100%;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
}
@media (min-width: 768px) {
  .hero-panel-with-card.hero-panel-contain-card .hero-card-container {
    width: 98.1818181818%;
    max-width: 1341.1636363636px;
    margin-left: 0.9090909091%;
    margin-right: 0.9090909091%;
    margin: 0 auto;
  }
}
@media only screen and (min-width: 1024px) {
  .hero-panel-with-card.hero-panel-contain-card .hero-card-container {
    max-width: 1366px;
  }
}
.hero-panel-with-card.hero-panel-contain-card .hero-card-container .hero-card {
  position: relative;
  margin: 0;
  width: 100%;
  /* Small devices (tablets, 768px and up) */
}
@media (min-width: 768px) {
  .hero-panel-with-card.hero-panel-contain-card .hero-card-container .hero-card {
    width: 48.1818181818%;
    max-width: 658.1636363636px;
    margin-left: 0.9090909091%;
    margin-right: 0.9090909091%;
    float: left;
  }
  .lang-ar .hero-panel-with-card.hero-panel-contain-card .hero-card-container .hero-card {
    float: right;
  }
}
.hero-panel-with-card.hero-panel-contain-card .hero-card-container .hero-card + .hero-card {
  top: -16px;
  /* Small devices (tablets, 768px and up) */
}
@media (min-width: 768px) {
  .hero-panel-with-card.hero-panel-contain-card .hero-card-container .hero-card + .hero-card {
    top: 0;
  }
}
.hero-panel-with-card.hero-panel-contain-card .hero-card-container .hero-card .card-image {
  float: none;
}
.hero-panel-with-card.hero-panel-contain-card .hero-card-container .hero-card .card-content {
  width: 86.9047619048%;
  max-width: 666.5595238095px;
  margin-left: 2.380952381%;
  margin-right: 2.380952381%;
  padding-left: 18px;
  padding-right: 17px;
  background-color: #ffffff;
  float: none;
  text-align: center;
  box-shadow: 10px 10px 20px 0 rgba(0, 0, 0, 0.06);
  position: relative;
  top: -56px;
  margin: auto;
  border-radius: 4px;
  padding-top: 24px;
  padding-bottom: 28px;
  min-height: 175px;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
.lang-ar .hero-panel-with-card.hero-panel-contain-card .hero-card-container .hero-card .card-content {
  padding-left: 17px;
  padding-right: 18px;
}
@media (min-width: 768px) {
  .hero-panel-with-card.hero-panel-contain-card .hero-card-container .hero-card .card-content {
    width: 80.6451612903%;
    max-width: 825.8064516129px;
    margin-left: 1.3440860215%;
    margin-right: 1.3440860215%;
    padding-left: 20px;
    padding-right: 22px;
    margin: auto;
    padding-bottom: 27px;
  }
  .lang-ar .hero-panel-with-card.hero-panel-contain-card .hero-card-container .hero-card .card-content {
    padding-left: 22px;
    padding-right: 20px;
  }
}
@media only screen and (min-width: 1024px) {
  .hero-panel-with-card.hero-panel-contain-card .hero-card-container .hero-card .card-content {
    padding-left: 19px;
    padding-right: 17px;
    top: -86px;
    padding-top: 25px;
  }
  .lang-ar .hero-panel-with-card.hero-panel-contain-card .hero-card-container .hero-card .card-content {
    padding-left: 17px;
    padding-right: 19px;
  }
}
@media only screen and (min-width: 1366px) {
  .hero-panel-with-card.hero-panel-contain-card .hero-card-container .hero-card .card-content {
    width: 72.311827957%;
    max-width: 740.4731182796px;
    margin-left: 1.3440860215%;
    margin-right: 1.3440860215%;
    padding-left: 40px;
    padding-right: 41px;
    margin: 0 auto;
  }
  .lang-ar .hero-panel-with-card.hero-panel-contain-card .hero-card-container .hero-card .card-content {
    padding-left: 41px;
    padding-right: 40px;
  }
}
.hero-panel-with-card.hero-panel-contain-card .hero-card-container .hero-card .card-content a {
  text-decoration: none;
  color: #404040;
}
.hero-panel-with-card.hero-panel-contain-card .hero-card-container .hero-card .card-content a:hover, .hero-panel-with-card.hero-panel-contain-card .hero-card-container .hero-card .card-content a:focus {
  text-decoration: none;
  color: #404040;
}
.hero-panel-with-card.hero-panel-contain-card .hero-card-container .hero-card .card-content .card-title {
  font-family: "Amaranth", "Open Sans", Arial, sans-serif;
  font-weight: 700;
  letter-spacing: 0;
  font-size: 20px;
  font-size: 1.25rem;
  line-height: 1.3;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  .hero-panel-with-card.hero-panel-contain-card .hero-card-container .hero-card .card-content .card-title {
    font-size: 20px;
    font-size: 1.25rem;
  }
}
@media only screen and (min-width: 1024px) {
  .hero-panel-with-card.hero-panel-contain-card .hero-card-container .hero-card .card-content .card-title {
    font-size: 20px;
    font-size: 1.25rem;
  }
}
@media only screen and (min-width: 1366px) {
  .hero-panel-with-card.hero-panel-contain-card .hero-card-container .hero-card .card-content .card-title {
    font-size: 24px;
    font-size: 1.5rem;
    line-height: 1.25;
  }
}
.hero-panel-with-card.hero-panel-contain-card .hero-card-container .hero-card .card-content .card-body {
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 400;
  font-size: 13px;
  font-size: 0.8125rem;
  line-height: 1.84;
  letter-spacing: 0.7px;
  margin-top: 20px;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  .hero-panel-with-card.hero-panel-contain-card .hero-card-container .hero-card .card-content .card-body {
    margin-top: 15px;
  }
}
@media only screen and (min-width: 1024px) {
  .hero-panel-with-card.hero-panel-contain-card .hero-card-container .hero-card .card-content .card-body {
    margin-top: 13px;
  }
}
@media only screen and (min-width: 1366px) {
  .hero-panel-with-card.hero-panel-contain-card .hero-card-container .hero-card .card-content .card-body {
    margin-top: 17px;
  }
}
.hero-panel-with-card.hero-panel-contain-card .hero-card-container .hero-card .card-content .read-more {
  margin-top: 19px;
  margin-bottom: 0;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  .hero-panel-with-card.hero-panel-contain-card .hero-card-container .hero-card .card-content .read-more {
    margin-top: 7px;
  }
}
@media only screen and (min-width: 1024px) {
  .hero-panel-with-card.hero-panel-contain-card .hero-card-container .hero-card .card-content .read-more {
    margin-top: 9px;
  }
}
@media only screen and (min-width: 1366px) {
  .hero-panel-with-card.hero-panel-contain-card .hero-card-container .hero-card .card-content .read-more {
    margin-top: 19px;
  }
}
.hero-panel-with-card.hero-panel-contain-card .hero-card-container .hero-card .card-content .read-more a {
  color: #0073cd;
  text-decoration: underline;
}
.hero-panel-with-card.hero-panel-contain-card .hero-card-container .hero-card .card-content .read-more a:hover, .hero-panel-with-card.hero-panel-contain-card .hero-card-container .hero-card .card-content .read-more a:focus {
  color: #0073cd !important;
  text-decoration: none;
}
.home-page .hero-panel-with-card.hero-panel-contain-card .hero-card-container .hero-card .card-content {
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  .home-page .hero-panel-with-card.hero-panel-contain-card .hero-card-container .hero-card .card-content {
    top: 0;
    margin: -56px auto 30px;
  }
}
@media only screen and (min-width: 1024px) {
  .home-page .hero-panel-with-card.hero-panel-contain-card .hero-card-container .hero-card .card-content {
    margin: -86px auto 30px;
  }
}
@media only screen and (min-width: 1366px) {
  .home-page .hero-panel-with-card.hero-panel-contain-card .hero-card-container .hero-card .card-content {
    margin-left: auto;
    margin-right: auto;
  }
  .lang-ar .home-page .hero-panel-with-card.hero-panel-contain-card .hero-card-container .hero-card .card-content {
    margin-left: auto;
    margin-right: auto;
  }
}